import {
	ACTION_PRODUCTS_ERROR,
	ACTION_PRODUCTS_START,
	GET_PRODUCTS_SUCCESS,
	GET_FILTERS_SUCCESS,
} from "../../types/index";

import clienteAxios from "../../../Axios";

import universalCookie from "universal-cookie";

const cookie = new universalCookie();

export function getProducts(indexID, { isSupplier, inStock, page, minPrice, maxPrice, textSearch, filterSearch, isUpdated, hasPrice, currency }) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {
			const invalidCharacters = new RegExp("[{}()\\[\\]<>]");
			let indicesID = "";
			indexID.forEach(index => {
				indicesID += `${index},`
			}
			);
			if (!invalidCharacters.test(textSearch)) {
				const response = await clienteAxios({
					method: "GET",
					url: `products?indexID=${indicesID.slice(0, -1)}&textSearch=${textSearch}&filterSearch=${filterSearch.join(" ")}&isSupplier=${isSupplier}&inStock=${inStock}&page=${page}&minPrice=${minPrice}&maxPrice=${maxPrice}&isUpdated=${isUpdated}&hasPrice=${hasPrice}&currency=${currency}`,
					headers: {
						"x-acces-token": cookie.get("token"),
					},
				});
				dispatch(getProductsSuccess({ resp: response.data.response, isSupplier, inStock, minPrice, maxPrice, textSearch, filterSearch, isUpdated, hasPrice, currency }));
			} else {
				throw new Error('Busqueda con caracteres inválidos');
			}
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

export function getProductsFilters(index) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {
			const respuesta = await clienteAxios.get(`filter?productType=${index}`);
			dispatch(getFiltersSuccess(respuesta.data.response)); // TODO -< check error code para ver si exito o succes, no???
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

const actionProductsError = () => ({
	type: ACTION_PRODUCTS_ERROR,
});

const actionProductsStart = () => ({
	type: ACTION_PRODUCTS_START,
});

const getProductsSuccess = (payload) => ({
	type: GET_PRODUCTS_SUCCESS,
	payload: payload,
});

const getFiltersSuccess = (payload) => ({
	type: GET_FILTERS_SUCCESS,
	payload: payload,
});
