import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import App from "./routes/App";
import store from "./redux/store";

import "./index.css";
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

const root = createRoot(document.getElementById("root"));

root.render(
	<Provider store={store}>
		<MantineProvider >
			<Notifications />
			<App />
		</MantineProvider>
	</Provider>,
);