import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import { Accordion, NativeSelect, Select } from '@mantine/core';
import { v4 as uuidv4 } from "uuid";

import { getClients } from '../../../redux/actions/entities/clientAction';
import { changeQuotation, createQuotation, getClientsActiveQuotations } from '../../../redux/actions/utilities/quotationAction';

import './quotationSelector.css'

export const QuotationSelector = () => {
    const dispatch = useDispatch();

    const clients = useSelector((state) => state.clients.clients)
    const currency = useSelector((state) => state.products.currency)
    const quotations = useSelector((state) => state.quotation.list)
    const quotationClient = useSelector((state) => state.quotation.client)
    const quotationID = useSelector((state) => state.quotation.quotation)

    const [selectedClient, setSelectedClient] = useState(quotationClient || null);

    useEffect(() => {
        dispatch(getClients());
    }, [dispatch])

    useEffect(() => {
        if (selectedClient)
            dispatch(getClientsActiveQuotations(selectedClient))
    }, [selectedClient, dispatch])

    const handleQuotationChange = (quotationID) => {
        if (quotationID === "new") {
            dispatch(createQuotation(selectedClient, currency))
            setTimeout(() => {
                dispatch(getClientsActiveQuotations(selectedClient))
            }, 500);
        }
        else
            dispatch(changeQuotation(selectedClient, quotationID, currency))
    }

    const optionsFilter = ({ options, search }) => {
        if (clientsName.length) {
            const filtered = clientsName.filter((client) =>
                client.info.name.toLowerCase().trim().includes(search.toLowerCase().trim())
                || client.info.fantasyName.toLowerCase().trim().includes(search.toLowerCase().trim())
                || client.info.CUIT.toLowerCase().trim().includes(search.toLowerCase().trim())
            );

            return filtered;
        }
    };

    let clientsName = []
    if (clients.length) {
        clientsName = clients.map((client) => {
            return {
                ...client,
                value: client._id,
                label: client.info.name
            }
        })
    }

    return (
        <>
            {clients.length > 0 && <Accordion variant="contained" className="filters-container" defaultValue="filters">
                <Accordion.Item key={1} value="filters">
                    <Accordion.Control>COTIZACIÓN</Accordion.Control>
                    <Accordion.Panel>
                        <Select
                            label="Cliente seleccionado"
                            placeholder="Elija cliente"
                            data={clientsName}
                            filter={optionsFilter}
                            nothingFoundMessage="Sin resultados..."
                            searchable
                            size="xs"
                            onOptionSubmit={(e) => setSelectedClient(e)}
                            value={selectedClient || quotationClient}
                        />
                        <NativeSelect
                            label="Cotizacion seleccionada"
                            onChange={(e) => handleQuotationChange(e.target.value)}
                            size="xs"
                            disabled={!selectedClient && !quotationClient}
                        >
                            <option hidden>Seleccionar cotizacion</option>
                            {quotations && quotations.length > 0 && quotations.map((quotation) => {
                                return (
                                    <option value={quotation._id} key={quotation._id} selected={quotationID === quotation._id}>
                                        {quotation.code}
                                    </option>
                                );
                            })}
                            <option value="new">Nueva cotización</option>
                        </NativeSelect>
                        {selectedClient && quotationClient && <Link to={"/Cotizaciones"} key={uuidv4()} style={{ textDecoration: "none" }}>
                            <span className='quotationSelector-button'>Ir a cotización</span>
                        </Link>}
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>}
        </>
    )
}
