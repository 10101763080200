import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Input, NativeSelect, Modal } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import 'dayjs/locale/es';

import StatusModal from "../../utilities/Modal/StatusModal";

import { addCollaborator } from "../../../redux/actions/entities/collaboratorAction";

export default function ModalAddCollaborator({ setShowAddCollaboratorModal }) {
    const dispatch = useDispatch();

    const message = useSelector((state) => state.collaborators.message);
    const loading = useSelector((state) => state.collaborators.loading);
    const error = useSelector((state) => state.collaborators.error);

    const [inputName, setInputName] = useState("");
    const [inputLastName, setLastInputName] = useState("");
    const [inputDocType, setInputDocType] = useState("");
    const [inputDocNum, setInputDocNum] = useState("");
    const [inputCUIL, setInputCUIL] = useState("");
    const [inputBirthday, setInputBirthday] = useState(null);
    const [inputEmail, setInputEmail] = useState("");
    const [inputPhone, setInputPhone] = useState("");
    const [inputRole, setInputRole] = useState("");

    const [showStatusModal, setShowStatusModal] = useState(false);

    useEffect(() => {
        if (!showStatusModal && inputName) {
            setShowAddCollaboratorModal(false)
        }
    }, [showStatusModal])

    const handleAddClient = () => {
        dispatch(addCollaborator(inputName, inputLastName, inputDocType, inputDocNum, inputCUIL, inputBirthday, inputEmail, inputPhone, inputRole));
        setShowStatusModal(true);
    };

    return (
        <Modal
            centered
            title="Crear colaborador"
            opened={true}
            onClose={() => { setShowAddCollaboratorModal(false) }}
            overlayProps={{
                backgroundOpacity: 0.5,
                blur: 3,
                zIndex: 1100
            }}
        >
            <div className="entitiy-addModal-container">
                <section>
                    <Input id="inputName" placeholder="Nombre *" value={inputName} onChange={(e) => setInputName(e.target.value)} />
                    <Input id="inputLastName" placeholder="Apellido *" value={inputLastName} onChange={(e) => setLastInputName(e.target.value)} />
                </section>
                <section>
                    <NativeSelect value={inputDocType} onChange={(e) => setInputDocType(e.target.value)}>
                        <option hidden>Tipo documento</option>
                        <option value="DNI">DNI</option>
                    </NativeSelect>
                    <Input id="inputDocNum" placeholder="Nro. documento *" disabled={!inputDocType} value={inputDocNum} onChange={(e) => setInputDocNum(e.target.value)} />
                </section>
                <section>
                    <Input id="inputCUIL" placeholder="CUIL *" value={inputCUIL} onChange={(e) => setInputCUIL(e.target.value)} />
                    <DatePickerInput
                        value={inputBirthday}
                        hideOutsideDates
                        locale="es"
                        onChange={setInputBirthday}
                        placeholder="Fecha nacimiento"
                        maxDate={new Date()}
                    />
                </section>
                <section>
                    <Input id="inputEmail" placeholder="Email *" value={inputEmail} onChange={(e) => setInputEmail(e.target.value)} />
                </section>
                <section>
                    <Input id="inputPhone" placeholder="Telefono *" value={inputPhone} onChange={(e) => setInputPhone(e.target.value)} />
                    <NativeSelect value={inputRole} onChange={(e) => setInputRole(e.target.value)}>
                        <option hidden>Rol</option>
                        <option value="admin">Administrador</option>
                        <option value="user">Usuario</option>
                    </NativeSelect>
                </section>
                <button onClick={handleAddClient} disabled={!inputRole || !inputName || !inputLastName || !inputEmail}>
                    Crear
                </button>
            </div>
            {showStatusModal && (
                <StatusModal loading={loading} error={error} setModalStatusCloseAction={setShowStatusModal}>
                    {message}
                </StatusModal>
            )}
        </Modal>
    );
}