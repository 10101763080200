import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Input, NativeSelect } from '@mantine/core';
import { v4 as uuidv4 } from "uuid";

import { addClientContact, addClientDirection, deleteClient, deleteClientContact, deleteClientDirection, getClientByID, updateClient, updateClientContact, updateClientDirection } from '../../../redux/actions/entities/clientAction';

import ConfirmationModal from '../../utilities/Modal/ConfirmationModal';
import StatusModal from '../../utilities/Modal/StatusModal';
import ModalDirection from '../modals/ModalDirection';
import ModalContact from '../modals/ModalContact';

import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";
import iconAddNew from "../../../assets/svg/functionalities-icons/plus_blue.svg";
import iconTrashCan from "../../../assets/svg/functionalities-icons/trash-can.svg";
import iconEdit from "../../../assets/svg/functionalities-icons/lapiz-editar.svg";
import reverseIcon from "../../../assets/svg/functionalities-icons/arrow-open_white.svg"
import { QuotationRecord } from '../../quotation/quotationRecord/QuotationRecord';

export const Client = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const navegation = useNavigate();

    const client = useSelector((state) => state.clients.selectedClient)
    const clientStatus = useSelector((state) => state.clients)

    const [clientID] = useState(params.get("id") || "");

    const [closeSection, setCloseSection] = useState(true)

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)
    const [showDirectionModal, setShowDirectionModal] = useState(false)
    const [showContactModal, setShowContactModal] = useState(false)

    const [editMode, setEditMode] = useState(false)
    const [clientInfoCopy, setClientInfoCopy] = useState(client ? client.info : {})

    useEffect(() => {
        dispatch(getClientByID(clientID));
    }, [])

    useEffect(() => {
        if (!showStatusModal) {
            if (showConfirmationModal && showConfirmationModal.title === "Eliminar cliente" && !editMode) {
                navegation(`/Clientes`);
                setShowConfirmationModal(false)
            } else {
                dispatch(getClientByID(clientID));
                setShowConfirmationModal(false)
            }
        }
    }, [showStatusModal])


    const handleContactAction = (name, lastName, email, phone) => {
        if (showContactModal.data) dispatch(updateClientContact(name, lastName, email, phone, showContactModal.index, clientID))
        else dispatch(addClientContact(name, lastName, email, phone, clientID))
        setShowContactModal(false)
        setShowStatusModal(true)
        setEditMode(false)
    }

    const handleDirectionAction = (country, province, locality, neighborhood, street, number, floor, postalCode) => {
        if (showDirectionModal.data) dispatch(updateClientDirection(country, province, locality, neighborhood, street, number, floor, postalCode, showDirectionModal.index, clientID))
        else dispatch(addClientDirection(country, province, locality, neighborhood, street, number, floor, postalCode, clientID))

        setShowDirectionModal(false)
        setShowStatusModal(true)
        setEditMode(false)
    }

    const handlerShowConfirmationModal = () => {
        if (showConfirmationModal.title === "Eliminar cliente") {
            dispatch(deleteClient(clientID))
        } else if (showConfirmationModal.title === "Eliminar contacto") {
            dispatch(deleteClientContact(showConfirmationModal.index, clientID))
        } else {
            dispatch(deleteClientDirection(showConfirmationModal.index, clientID))
        }
        setShowStatusModal(true)
    }

    const handleUpdateClient = () => {
        dispatch(updateClient(clientInfoCopy, clientID))
        setEditMode(false)
        setShowStatusModal(true)
    }

    return (
        <>
            <div className="cuentas-contenedor">
                {client && client.info &&
                    <section className='entity-backToSection-container'>
                        <Link to={"/Clientes"} key={uuidv4()}>
                            <img alt="reverse" src={reverseIcon} />
                        </Link>
                        <h3>| Cliente:</h3>
                        <h4>{client.info.name}</h4>
                    </section>
                }
                <div className="style-section-row" onClick={() => { setCloseSection(!closeSection) }}>
                    <h2>Datos del cliente</h2>
                    <img alt='arrow-icon' src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
                </div>
                <hr />
                {client && client.info && <div className={closeSection ? "entity-info-container" : "d-none"} >
                    <div>
                        <h3>Nombre</h3>
                        {!editMode ? <h4>{client.info.name}</h4> : <Input size="xxs" id="nameInputForm" value={clientInfoCopy.name} onChange={(e) => setClientInfoCopy({
                            ...clientInfoCopy,
                            name: e.target.value
                        })} />}
                    </div>
                    <div>
                        <h3>Razón social</h3>
                        {!editMode ? <h4>{client.info.fantasyName}</h4> : <Input size="xxs" id="fantasyNameInputForm" value={clientInfoCopy.fantasyName} onChange={(e) => setClientInfoCopy({
                            ...clientInfoCopy,
                            fantasyName: e.target.value
                        })} />}
                    </div>
                    <div style={editMode ? { margin: "10px 0" } : {}}>
                        <h3>Código de identificación</h3>
                        <h4>{client.info.code}</h4>
                    </div>
                    <div>
                        <h3>{client.info.taxCategory === "Consumidor final" ? "DNI" : "CUIT"}</h3>
                        {!editMode ? <h4>{client.info.CUIT}</h4> : <Input size="xxs" id="CUITInputForm" value={clientInfoCopy.CUIT} onChange={(e) => setClientInfoCopy({
                            ...clientInfoCopy,
                            CUIT: e.target.value
                        })} />}
                    </div>
                    <div>
                        <h3>Industria</h3>
                        {!editMode ? <h4>{client.info.industry}</h4> : <Input size="xxs" id="industryInputForm" value={clientInfoCopy.industry} onChange={(e) => setClientInfoCopy({
                            ...clientInfoCopy,
                            industry: e.target.value
                        })} />}
                    </div>
                    <div>
                        <h3>Categoría fiscal</h3>
                        {!editMode ? <h4>{client.info.taxCategory}</h4> :
                            <NativeSelect size="xxs" id="taxCategoryInput" onChange={(e) => setClientInfoCopy({
                                ...clientInfoCopy,
                                taxCategory: e.target.value
                            })} >
                                <option value="IVA excento">IVA excento</option>
                                <option value="Responsable inscripto">Responsable inscripto</option>
                                <option value="Consumidor final">Consumidor final</option>
                            </NativeSelect>
                        }
                    </div>
                    <h3>Domicilios o sucursales</h3>
                    <section>
                        {
                            client.directions.map((direction, index) => {
                                return (
                                    <div key={direction.street + direction.number}>
                                        <section>
                                            <h5>{direction.street} {direction.number} {direction.floor} - {direction.neighborhood} ({direction.postalCode})</h5>
                                            <h6>{direction.locality} - {direction.province} - {direction.country}</h6>
                                        </section>
                                        {editMode && <section>
                                            <img
                                                alt="edit-button"
                                                src={iconEdit}
                                                className="cuentas-comercial-button_undo"
                                                onClick={() => setShowDirectionModal({ action: "Añadir", data: direction, index, type: "direction" })}
                                            />
                                            <img
                                                alt="confirm-button"
                                                src={iconTrashCan}
                                                className="cuentas-comercial-button_undo"
                                                onClick={() => setShowConfirmationModal({
                                                    description: `¿Está seguro que desea eliminar la dirección "${direction.street + " " + direction.number + " - " + direction.neighborhood}"?`,
                                                    title: "Eliminar dirección",
                                                    index
                                                })}
                                            />
                                        </section>}
                                    </div>
                                )
                            })
                        }
                        {editMode && <div onClick={() => setShowDirectionModal({ action: "Añadir", type: "direction" })}>
                            <div>
                                <h6>Añadir</h6>
                                <img alt='add-icon' src={iconAddNew} />
                            </div>
                        </div>}
                    </section>
                    {!client.directions.length && !editMode && <h5 style={{ marginBottom: "20px", marginLeft: "20px" }}>Sin registros</h5>}
                    <h3>Ejecutivos de cuenta</h3>
                    <section>
                        {
                            client.contacts.map((contact, index) => {
                                {
                                    return (
                                        <div key={contact.name + contact.lastName}>
                                            <section>
                                                <h5>{contact.lastName} {contact.name}</h5>
                                                <h6>Email: {contact.email}</h6>
                                                <h6>Teléfono: {contact.phone}</h6>
                                            </section>
                                            {editMode && <section>
                                                <img
                                                    alt="edit-button"
                                                    src={iconEdit}
                                                    className="cuentas-comercial-button_undo"
                                                    onClick={() => setShowContactModal({ action: "Añadir", data: contact, index, type: "contact" })}
                                                />
                                                <img
                                                    alt="confirm-button"
                                                    src={iconTrashCan}
                                                    className="cuentas-comercial-button_undo"
                                                    onClick={() => setShowConfirmationModal({
                                                        description: `¿Está seguro que desea eliminar el contacto "${contact.lastName + " " + contact.name}"?`,
                                                        title: "Eliminar contacto",
                                                        index
                                                    })}
                                                />
                                            </section>}
                                        </div>
                                    )
                                }
                            })
                        }
                        {editMode && <div onClick={() => setShowContactModal({ action: "Añadir", type: "contact" })}>
                            <div>
                                <h6>Añadir</h6>
                                <img alt='add-icon' src={iconAddNew} />
                            </div>
                        </div>}
                    </section>
                    {!client.contacts.length && !editMode && <h5 style={{ marginLeft: "20px" }}>Sin registros</h5>}
                    <div>
                        {/* <img />
                        <img />
                        <img /> */}
                    </div>
                    {!editMode ?
                        <div>
                            <button className="entity-button-blue" onClick={() => { setEditMode(true); setClientInfoCopy(client.info) }}>Editar</button>
                            <button className="entity-button-red" onClick={() => setShowConfirmationModal({
                                title: "Eliminar cliente",
                                description: `¿Está seguro que desea eliminar el cliente "${client.info.name}"?`
                            })}>Eliminar</button>
                        </div> :
                        <div>
                            <button className="entity-button-blueWhite" onClick={() => setEditMode(false)}>Cancelar</button>
                            <button className="entity-button-blue"
                                disabled={!clientInfoCopy.name || !clientInfoCopy.fantasyName || !clientInfoCopy.CUIT || !clientInfoCopy.industry || !clientInfoCopy.taxCategory}
                                onClick={handleUpdateClient}>
                                Confirmar</button>
                        </div>
                    }
                </div>}
                {showConfirmationModal &&
                    <ConfirmationModal
                        descriptionText={showConfirmationModal.description}
                        handlerShowConfirmationModal={handlerShowConfirmationModal}
                        setShowConfirmationModal={setShowConfirmationModal}
                        titleText={showConfirmationModal.title}
                    />}
                {showStatusModal &&
                    <StatusModal error={clientStatus.error} loading={clientStatus.loading} setModalStatusCloseAction={setShowStatusModal}>
                        {clientStatus.message}
                    </StatusModal>}
                {showDirectionModal && <ModalDirection handleDirectionAction={handleDirectionAction} showDirectionModal={showDirectionModal} setShowDirectionModal={setShowDirectionModal} />}
                {showContactModal && <ModalContact handleContactAction={handleContactAction} showContactModal={showContactModal} setShowContactModal={setShowContactModal} />}
                {clientID && <QuotationRecord clientID={clientID} isHome={false} />}
            </div>
        </>
    )
}
