import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import EntityCard from '../EntityCard';
import ModalAddCollaborator from './ModalAddCollaborator';

import { getCollaborators } from '../../../redux/actions/entities/collaboratorAction';

import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";
import iconAddNew from "../../../assets/svg/functionalities-icons/plus_white.svg";

import "../entities.css"
import { TextSearcher } from '../../utilities/components/TextSearcher';

const Collaborators = () => {
    const dispatch = useDispatch();

    const collaborators = useSelector((state) => state.collaborators.collaborators)

    const [closeSection, setCloseSection] = useState(true)
    const [searcherInput, setSearcherInput] = useState("");

    const [showAddCollaboratorModal, setShowAddCollaboratorModal] = useState(false)

    document.title = "Scope | Colaboradores"

    useEffect(() => {
        if (!showAddCollaboratorModal) {
            dispatch(getCollaborators());
        }
    }, [showAddCollaboratorModal, dispatch])

    return (
        <div className="cuentas-contenedor">
            <div className="style-section-row" onClick={() => { setCloseSection(!closeSection) }}>
                <h2>Colaboradores</h2>
                <img alt="arrow" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
            </div>
            <hr />
            <div className={closeSection ? "" : "d-none"} >
                <div className="entity-filters-container">
                    <TextSearcher placeholder="Nombre, apellido o CUIL" setSearchFilter={setSearcherInput} />
                    <section >
                        <img alt="add" src={iconAddNew} className="entity-addNew-botton" onClick={() => setShowAddCollaboratorModal(!showAddCollaboratorModal)} />
                    </section>
                </div>
                <section className="entity-cards-container">
                    {collaborators &&
                        collaborators.length > 0 &&
                        collaborators.map((collaborator) => {
                            if ((collaborator.info.name && collaborator.info.name.toLowerCase().includes(searcherInput.toLowerCase())) ||
                                (collaborator.info.lastName && collaborator.info.lastName.toLowerCase().includes(searcherInput.toLowerCase())) ||
                                (collaborator.info.CUIL && collaborator.info.CUIL.toLowerCase().includes(searcherInput.toLowerCase()))) {
                                return (
                                    <EntityCard
                                        key={collaborator._id}
                                        mainText={collaborator.info.lastName + " " + collaborator.info.name}
                                        secondaryText={collaborator.role.value}
                                        entityID={collaborator._id}
                                        type="collaborator" />
                                );
                            }
                            return null
                        })}
                </section>

            </div>
            {showAddCollaboratorModal && <ModalAddCollaborator setShowAddCollaboratorModal={setShowAddCollaboratorModal} />}
        </div>
    )
}

export default Collaborators