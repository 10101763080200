import {
	ACTION_NAVBAR_CATEGORIES_ERROR,
	ACTION_NAVBAR_CATEGORIES_START,
	GET_NAVBAR_CATEGORIES_SUCCESS,
	NAVBAR_HIDE,
	NAVBAR_SHOW,
	SET_NAVBAR_CATEGORIES_MESSAGE
} from "../../types";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function addNavbarCategory(categoryName, categoryImages, object) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "POST",
				url: "navbarCategory",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { categoryName, categoryImages, object },
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function addNavbarIndex(subcategoryID, indexName) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "POST",
				url: "navbarCategoryIndex",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { subcategoryID, indexName },
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function addNavbarSubcategory(categoryID, subcategoryName) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "POST",
				url: "navbarCategorySubcategory",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { categoryID, subcategoryName },
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function deleteNavbarCategory(navbarCategoryID) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `navbarCategory/?navbarCategoryID=${navbarCategoryID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function deleteNavbarIndex(indexID) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `navbarCategoryIndex`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { indexID },
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function deleteNavbarSubcategory(subcategoryID) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `navbarCategorySubcategory`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { subcategoryID },
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function getNavbarCategories() {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `navbarCategory`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getNavbarCategorySuccess(response.data.response));
		} catch (e) {
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function moveNavbarIndex(subcategoryID, indexID) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: `navbarIndexMove/?subcategoryID=${subcategoryID}&indexID=${indexID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function updateNavbarCategory(categoryID, newCategoryName) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: `navbarCategoryCategory/?categoryID=${categoryID}&newCategoryName=${newCategoryName.trim()}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function updateNavbarIndex(indexID, newIndexName) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: `navbarCategoryIndex/?indexID=${indexID}&newIndexName=${newIndexName.trim()}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function updateNavbarSubcategory(subcategoryID, newSubcategoryName) {
	return async (dispatch) => {
		dispatch(actionNavbarCategoryStart());
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: `navbarCategorySubcategory/?subcategoryID=${subcategoryID}&newSubcategoryName=${newSubcategoryName.trim()}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setNavbarCategoryMessage(response.data.message))
			dispatch(getNavbarCategories());
		} catch (error) {
			dispatch(setNavbarCategoryMessage(error.response.data.message))
			dispatch(actionNavbarCategoryError());
		}
	};
}

export function setNavbarCategoryMessage(message) {
	return (dispatch) => {
		dispatch(actionSetNavbarCategoryMessage(message))
	};
}

export function hideNavBar() {
	return (dispatch) => {
		dispatch(actionHideNavBar());
	};
}

export function showNavBar() {
	return (dispatch) => {
		dispatch(actionShowNavBar());
	};
}

const actionHideNavBar = () => ({
	type: NAVBAR_HIDE,
});

const actionShowNavBar = () => ({
	type: NAVBAR_SHOW,
});

const actionNavbarCategoryError = () => ({
	type: ACTION_NAVBAR_CATEGORIES_ERROR,
});

const actionNavbarCategoryStart = () => ({
	type: ACTION_NAVBAR_CATEGORIES_START,
});

const actionSetNavbarCategoryMessage = (message) => ({
	type: SET_NAVBAR_CATEGORIES_MESSAGE,
	payload: message
})

const getNavbarCategorySuccess = (response) => ({
	type: GET_NAVBAR_CATEGORIES_SUCCESS,
	payload: response,
});
