import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Input, NativeSelect, Modal } from '@mantine/core';

import StatusModal from "../../utilities/Modal/StatusModal";

import { addClient } from "../../../redux/actions/entities/clientAction";

export default function ModalAddClient({ setShowAddClientModal }) {
    const dispatch = useDispatch();

    const message = useSelector((state) => state.clients.message);
    const loading = useSelector((state) => state.clients.loading);
    const error = useSelector((state) => state.clients.error);

    const [inputName, setInputName] = useState("");
    const [inputFantasyName, setInputFantasyName] = useState("");
    const [inputCode, setInputCode] = useState("");
    const [inputCUIT, setInputCUIT] = useState("");
    const [inputIndustry, setInputIndustry] = useState("");
    const [selectTaxCategory, setSelectTaxCategory] = useState("");

    const [showStatusModal, setShowStatusModal] = useState(false);

    useEffect(() => {
        if (!showStatusModal && inputName && !error) {
            setShowAddClientModal(false)
        }
    }, [showStatusModal])

    const handleAddClient = () => {
        dispatch(addClient(inputName,
            inputFantasyName,
            inputCode,
            inputCUIT,
            inputIndustry,
            selectTaxCategory
        ));
        setShowStatusModal(true);
    };

    return (
        <Modal
            centered
            title="Nuevo cliente"
            opened={true}
            onClose={() => { setShowAddClientModal(false) }}
            overlayProps={{
                backgroundOpacity: 0.5,
                blur: 3,
                zIndex: 1100
            }}
        >
            <div className="entitiy-addModal-container">
                <section>
                    <Input id="inputName" placeholder="Nombre fantasía*" value={inputName} onChange={(e) => setInputName(e.target.value)} />
                </section>
                <section>
                    <Input id="inputFantasyName" placeholder="Razón social *" value={inputFantasyName} onChange={(e) => setInputFantasyName(e.target.value)} />
                </section>
                <section>
                    <Input.Wrapper description="Define un codigo con el cual identificar sus cotizaciones">
                        <Input minLength={3} maxLength={8} id="inputCode" placeholder="Codigo de identificación *" value={inputCode} onChange={(e) => setInputCode(e.target.value.toUpperCase())} />
                    </Input.Wrapper>
                </section>
                <section>
                    <Input id="inputCUIT" placeholder="CUIT o DNI *" value={inputCUIT} onChange={(e) => setInputCUIT(e.target.value)} />
                    <Input id="inputIndustry" placeholder="Industria" value={inputIndustry} onChange={(e) => setInputIndustry(e.target.value)} />
                </section>
                <section>
                    <NativeSelect onChange={(e) => setSelectTaxCategory(e.target.value)}>
                        <option hidden>Categoría fiscal</option>
                        <option value="IVA excento">IVA excento</option>
                        <option value="Responsable inscripto">Responsable inscripto</option>
                        <option value="Consumidor final">Consumidor final</option>
                    </NativeSelect>
                </section>
                <button onClick={handleAddClient} disabled={!inputName || !inputFantasyName || !inputCUIT || !selectTaxCategory || inputCode.length < 3}>
                    Crear
                </button>
            </div>
            {showStatusModal && (
                <StatusModal loading={loading} error={error} setModalStatusCloseAction={setShowStatusModal}>
                    {message}
                </StatusModal>
            )}
        </Modal>
    );
}
