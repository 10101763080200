import {
	ACTION_CURRENCY_START,
	ACTION_CURRENCY_SUCCESS,
	ACTION_CURRENCY_ERROR,
} from "../../types/index";

const initialState = {
	data: null,
	loading: null,
	error: null,
};

export default function currencyReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_CURRENCY_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case ACTION_CURRENCY_ERROR:
			return {
				...state,
				loading: false,
				error: true,
			};
		case ACTION_CURRENCY_SUCCESS:
			return {
				error: null,
				loading: false,
				data: action.payload,
			};
		default:
			return state;
	}
}
