import { ACTION_DASHBOARD_ERROR, ACTION_DASHBOARD_START, GET_DASHBOARD_SUCCESS } from "../../types/index";

const initialState = {
	dashboards: [],
	loading: false,
	error: false,
};
export default function dashboardReducer (state = initialState, action) {
	switch (action.type) {
		case ACTION_DASHBOARD_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case ACTION_DASHBOARD_ERROR:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_DASHBOARD_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				dashboards: action.payload,
			};
		default:
			return state;
	}
}
