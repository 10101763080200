import {
	ACTION_PRODUCT_START,
	ACTION_PRODUCT_ERROR,
	GET_PRODUCT_SUCCESS,
} from "../../types/index";

const initialState = {
	product: null,
	loading: false,
	error: false,
};

export default function productReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_PRODUCT_START:
			return {
				...state,
				error: false,
				loading: true,
			};
		case ACTION_PRODUCT_ERROR:
			return {
				product: null,
				error: true,
				loading: false,
			};
		case GET_PRODUCT_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				product: action.payload,
			};
		default:
			return state;
	}
}