import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Select } from '@mantine/core';

import { changeQuotation, createQuotation, getClientsOpenQuotations } from '../../../redux/actions/utilities/quotationAction';
import { getClients } from '../../../redux/actions/entities/clientAction';

import './quotationSearcher.css'

export const QuotationSearcher = () => {
    const dispatch = useDispatch();

    const clients = useSelector((state) => state.clients.clients)
    const currency = useSelector((state) => state.products.currency)
    const quotations = useSelector((state) => state.quotation.list)
    const quotationClient = useSelector((state) => state.quotation.client)
    const quotationID = useSelector((state) => state.quotation.quotation)

    const [selectedClient, setSelectedClient] = useState(quotationClient || null);

    useEffect(() => {
        dispatch(getClients());
    }, [dispatch])

    useEffect(() => {
        if (selectedClient) dispatch(getClientsOpenQuotations(selectedClient))
    }, [selectedClient, dispatch])

    const handleQuotationChange = (selectedQuotationID) => {
        if (selectedQuotationID === "new") {
            dispatch(createQuotation(selectedClient, currency))
            setTimeout(() => {
                dispatch(getClientsOpenQuotations(selectedClient))
            }, 500);
        }
        else
            dispatch(changeQuotation(selectedClient, selectedQuotationID, currency))
    }

    const optionsFilter = ({ options, search }) => {
        if (clientsName.length) {
            const filtered = clientsName.filter((client) =>
                client.info.name.toLowerCase().trim().includes(search.toLowerCase().trim())
                || client.info.fantasyName.toLowerCase().trim().includes(search.toLowerCase().trim())
                || client.info.CUIT.toLowerCase().trim().includes(search.toLowerCase().trim())
            );

            return filtered;
        }
    };

    let clientsName = []
    if (clients.length) {
        clientsName = clients.map((client) => {
            return {
                ...client,
                value: client._id,
                label: client.info.name
            }
        })
    }

    return (
        <div className='quotationSearcher-container'>
            {clientsName.length > 0 && <Select
                label="Cliente seleccionado"
                placeholder="Elija cliente"
                data={clientsName}
                filter={optionsFilter}
                nothingFoundMessage="Sin resultados..."
                searchable
                size="xs"
                onOptionSubmit={(e) => setSelectedClient(e)}
                value={selectedClient || quotationClient}
            />}
            {selectedClient && <section>
                {quotations && quotations.map((quotation) => {
                    return (
                        <div key={quotation._id} disabled={quotationID === quotation._id}
                            onClick={() => handleQuotationChange(quotation._id)}>
                            {quotation.code}
                        </div>
                    );
                })}
                <div onClick={() => handleQuotationChange("new")}>+</div>
            </section>}
        </div>
    )
}
