import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { NativeSelect } from '@mantine/core';

import { deleteQuotationProduct, updateQuotationProduct } from "../../redux/actions/utilities/quotationAction";

import iconoDeshacer from "../../assets/svg/functionalities-icons/volver-porcentaje-lista.svg";
import iconTrashCan from "../../assets/svg/functionalities-icons/trash-can.svg";
import iconEdit from "../../assets/svg/functionalities-icons/lapiz-editar.svg";
import iconoConfirmar from "../../assets/svg/functionalities-icons/check_white.svg";
import iconOpen from "../../assets/svg/functionalities-icons/arrow-close_blue.svg";

import './quotation.css'
import { setSelectedProduct } from "../../redux/actions/productos/productAction";

export const QuotationCard = ({ item }) => {
    const dispatch = useDispatch();

    const currency = useSelector((state) => state.products.currency);
    const priceLists = useSelector((state) => state.priceList.priceList);
    const userRol = useSelector((state) => state.auth.user.role);

    const quotationID = useSelector((state) => state.quotation.quotation);

    const [quotationEdit, setQuotationEdit] = useState("");
    const [quantityToUpdate, setQuantityToUpdate] = useState(0);

    const [openedCard, setOpenedCard] = useState(false)

    const handleDeleteQuotationItem = (productID) => {
        dispatch(deleteQuotationProduct(productID, quotationID, currency));
    };

    const handleEditQuotationItem = (id, cantidad) => {
        setQuotationEdit(id);
        setQuantityToUpdate(cantidad);
    };

    const handleUndoUpdate = () => {
        setQuotationEdit("");
    };

    const handleQuantityChange = (e) => {
        e.preventDefault();
        if (e.target.value < 1) {
            setQuantityToUpdate(1);
        } else {
            setQuantityToUpdate(parseInt(e.target.value));
        }
    };

    const handlePriceListChange = (priceListID, itemID) => {
        const updatedData = {
            newPriceListID: priceListID,
            productID: itemID,
        }
        dispatch(updateQuotationProduct(updatedData, quotationID, currency));
    };

    const handleUpdateQuantity = (itemID) => {
        const updatedData = {
            newQuantity: quantityToUpdate,
            productID: itemID,
        }
        dispatch(updateQuotationProduct(updatedData, quotationID, currency));
        setQuotationEdit("");
    };

    const transformPrice = (value, exchange) => {
        return new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: 2,  // Mínimo de 2 decimales
            maximumFractionDigits: 2   // Máximo de 2 decimales
        }).format((value * 1));
    };

    const handleOpenProduct = () => {
        dispatch(setSelectedProduct(item))
    }

    const outdatedProduct = Date.parse(new Date()) - Date.parse(item.timestamp) - 24 * 60 * 60 * 1000 > 0

    return (
        <>
            {openedCard ? <div>
                <div className="quotation-item-container" style={outdatedProduct ? { border: "2px solid var(--color-red)" } : {}}>
                    {outdatedProduct && <h4>Producto desactualizado</h4>}
                    <div>
                        <NativeSelect
                            value={item.priceList.percentage}
                            onChange={(e) => handlePriceListChange(e.target.value, item._id)}
                            size="xxs"
                        >
                            <option hidden>{item.priceList.name}</option>
                            {priceLists.map((priceList, index) => {
                                return (
                                    <option value={priceList._id} key={index}>
                                        {priceList.name}
                                    </option>
                                );
                            })}
                        </NativeSelect>
                        {item._id === quotationEdit ? (
                            <section className="quotation-edit-options">
                                <img
                                    alt="undo-button"
                                    src={iconoDeshacer}
                                    className="cuentas-comercial-button_undo"
                                    onClick={handleUndoUpdate} />
                                <img
                                    alt="confirm-button"
                                    src={iconoConfirmar}
                                    className="cuentas-comercial-button_confirm"
                                    onClick={() => handleUpdateQuantity(item._id)}
                                />
                            </section>
                        ) : (
                            <section className="quotation-edit-options">
                                <img
                                    alt="edit-button"
                                    src={iconEdit}
                                    className="cuentas-comercial-button_undo"
                                    onClick={() => handleEditQuotationItem(item._id, item.quantity)} />
                                <img
                                    alt="confirm-button"
                                    src={iconTrashCan}
                                    className="cuentas-comercial-button_undo"
                                    onClick={() => handleDeleteQuotationItem(item._id)}
                                />
                            </section>
                        )}
                    </div>
                    <div>
                        <Link to={`/Producto`} onClick={handleOpenProduct}>
                            <h2>{item.informacionProducto.titulo.toUpperCase()}</h2>
                        </Link>
                        <img alt="open-icon" src={iconOpen} onClick={() => { setOpenedCard(false); setQuotationEdit("") }} className="cuentas-comercial-button_undo" style={{ height: "30px", rotate: "180deg" }} />
                    </div>
                    <div>
                        <h3>PN: {item.informacionProducto.partNumber}</h3>
                        {userRol && userRol.access !== "user" ? <h4>Proveedor: {item.empresa.nombre}</h4> : <h4> </h4>}
                    </div>
                    <div className="quotation-stock-quantity-container">
                        <section>
                            <h5>Stock</h5>
                            <h6>{item.stock.cantStockTotal}</h6>
                        </section>
                        <section>
                            <h5>Cant.</h5>
                            {item._id !== quotationEdit ? (
                                <h6>{item.quantity}</h6>
                            ) : (
                                <input type="number" value={quantityToUpdate} onChange={handleQuantityChange} min={1} />
                            )}
                        </section>
                    </div>
                    <div>
                        <section className="quotation-stock-quantity-item">
                            <h5>Cant.</h5>
                            {item._id !== quotationEdit ? (
                                <h6>{item.quantity}</h6>
                            ) : (
                                <input type="number" value={quantityToUpdate} onChange={handleQuantityChange} min={1} />
                            )}
                        </section>
                        <section className="quotation-stock-quantity-item">
                            <h5>Stock</h5>
                            <h6>{item.stock.cantStockTotal}</h6>
                        </section>
                        <section>
                            <h5>{userRol && userRol.access !== "user" ? "Precio" : "Precio de venta"}</h5>
                            <h6>
                                {currency}{" "}
                                {userRol && userRol.access !== "user"
                                    ? transformPrice(item.normalizedCountedPrice, item.precio.tipoMoneda)
                                    : transformPrice(
                                        item.normalizedCountedPrice *
                                        1 *
                                        (item.priceList.percentage / 100 + 1),
                                        item.precio.tipoMoneda
                                    )}
                            </h6>
                        </section>
                        <section>
                            <h5>IVA</h5>
                            <h6>{transformPrice(item.precio.ivaProducto)}%</h6>
                        </section>
                        <section>
                            <h5>I.I.</h5>
                            <h6>{transformPrice(item.precio.impInterProducto)}%</h6>
                        </section>
                        <section>
                            <h5>{userRol && userRol.access !== "user" ? "Subtotal" : "Subtotal de venta"}</h5>
                            <h6>
                                {currency}{" "}
                                {userRol && userRol.access !== "user"
                                    ? transformPrice(
                                        (item.normalizedCountedPrice * 1 +
                                            item.normalizedCountedPrice * 0.01 * item.precio.ivaProducto +
                                            item.normalizedCountedPrice * 0.01 * item.precio.impInterProducto) *
                                        item.quantity,
                                        item.precio.tipoMoneda
                                    )
                                    : transformPrice(
                                        (item.normalizedCountedPrice * 1 +
                                            item.normalizedCountedPrice * 0.01 * item.precio.ivaProducto +
                                            item.normalizedCountedPrice * 0.01 * item.precio.impInterProducto) *
                                        (item.priceList.percentage / 100 + 1) *
                                        item.quantity,
                                        item.precio.tipoMoneda
                                    )}
                            </h6>
                        </section>
                    </div>
                </div>
                {userRol && userRol.access !== "user" && (
                    <section className="quotation-item-salePrice" style={outdatedProduct ? { border: "2px solid var(--color-red)" } : {}}>
                        <div>
                            <h6>Precio de venta:</h6>
                            <span>
                                {currency} {" "}
                                {transformPrice(
                                    item.normalizedCountedPrice *
                                    1 *
                                    (item.priceList.percentage / 100 + 1) *
                                    item.quantity,
                                    item.precio.tipoMoneda
                                )}
                            </span>
                        </div>
                        <div>
                            <h6>Precio de venta con IVA: </h6>
                            <span>
                                {currency} {" "}
                                {transformPrice(
                                    (item.normalizedCountedPrice * 1 +
                                        item.normalizedCountedPrice * 0.01 * item.precio.ivaProducto +
                                        item.normalizedCountedPrice * 0.01 * item.precio.impInterProducto) *
                                    (item.priceList.percentage / 100 + 1) *
                                    item.quantity,
                                    item.precio.tipoMoneda
                                )}
                            </span>
                        </div>
                    </section>
                )}
            </div> :
                <div>
                    <div className="quotation-item-container quotation-item-closed-container" style={outdatedProduct ? { border: "2px solid var(--color-red)" } : {}}>
                        <div>
                            <Link to={`/Producto`} onClick={handleOpenProduct}>
                                <h2>{item.informacionProducto.titulo.toUpperCase()}</h2>
                            </Link>
                            <img alt="icon-open" src={iconOpen} onClick={() => setOpenedCard(true)} className="cuentas-comercial-button_undo" />
                        </div>
                        <div >
                            <section>
                                <h5>{userRol && userRol.access !== "user" ? "Precio unitario" : "Precio unitario de venta"}</h5>
                                <h6>
                                    {currency}{" "}
                                    {userRol && userRol.access !== "user"
                                        ? transformPrice(item.normalizedCountedPrice, item.precio.tipoMoneda)
                                        : transformPrice(
                                            item.normalizedCountedPrice *
                                            1 *
                                            (item.priceList.percentage / 100 + 1),
                                            item.precio.tipoMoneda
                                        )}
                                </h6>
                            </section>
                            <section>
                                <h5>Cant.:</h5>
                                <h6>{item.quantity}</h6>
                            </section>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
