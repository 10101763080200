import React from 'react'
import { useNavigate } from "react-router-dom";

import './entities.css'

const EntityCard = ({ entityID, mainText, secondaryText, type }) => {
    const navegation = useNavigate();

    const handleContainerClick = (event) => {
        if (event.target.localName !== "img")
            if (type === "client") navegation(`/Cliente?id=${entityID}`);
            else navegation(`/Colaborador?id=${entityID}`);
    }

    return (
        <div className="entityCard-container" onClick={(e) => handleContainerClick(e)}>
            <div>
                <h3>{mainText}</h3>
                <h4>{secondaryText}</h4>
            </div>
        </div>
    )
}

export default EntityCard