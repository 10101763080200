import React, { useEffect, useState } from "react";

import IconoNotFound from "../../assets/svg/system-images/404_not_found_ilustracion.svg";

import "./notFound.css";

export default function NotFound() {

	const [actualNotFound, setActualNotFound] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setActualNotFound(true)
		}, 500);
	}, [])
	

	return (
		<>
			{actualNotFound ? 
				<div className="notFound-container">
					<img src={IconoNotFound} alt="not found" />
					<h2>Hubo un problema</h2>
					<h3>404 página no encontrada</h3>
					<a href="/">
						<button>VOLVER AL INICIO</button>
					</a>
				</div>
				:
				<div></div>
			}
		</>
	);
}
