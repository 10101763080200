import React, { useState, useEffect } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import { authUser, resetearEstadoErrorUser } from "../redux/actions/utilities/authAction";
import PrivateNoAdminRoutes from "../components/utilities/privateRoutes/PrivateNoAdminRoutes.js";

import Home from "../components/home/Home.js";
import Navbar from "../components/navbar/Navbar";
import Login from "../components/login/Login";
import NotFound from "../components/notFound/notFound.js";
import SelectedProduct from "../components/products/selectedProduct/SelectedProduct.js";
import MarketPlayers from "../components/entities/marketPlayers/MarketPlayers.js";
import CargaPlanillas from "../components/cargaPlanillas/CargaPlanillas.js";
import Quotation from "../components/quotation/Quotation";
import Products from "../components/products/Products";
import Categorization from "../components/configuration/categorization/Categorization.js";
import { IndexSearch } from "../components/entities/marketPlayers/indexSearch/IndexSearch.js";
import { Commercialization } from "../components/configuration/commercialization/Commercialization.js";
import Clients from "../components/entities/clients/Clients.js";
import { Client } from "../components/entities/clients/Client.js";
import Collaborators from "../components/entities/collaborators/Collaborators.js";
import { Collaborator } from "../components/entities/collaborators/Collaborator.js";

import '@mantine/dates/styles.css';
import PrivateSuperUserRoutes from "../components/utilities/privateRoutes/PrivateSuperUserRoutes.js";

export default function App() {
	const dispatch = useDispatch();

	const navbar = useSelector((state) => state.navbar);
	const auth = useSelector((state) => state.auth);
	const navbarCategories = useSelector((state) => state.navbar.categories);

	const [logInStatus, setLogInStatus] = useState(localStorage.getItem("LogInStatus") === "true");

	useEffect(() => {
		dispatch(authUser())
	}, [dispatch])

	useEffect(() => {
		if (auth.error) {
			setLogInStatus(false);
			localStorage.removeItem("user");
			localStorage.setItem("LogInStatus", false);
			dispatch(resetearEstadoErrorUser());
		}
	}, [auth.error, dispatch]);

	useEffect(() => {
		if (auth.user) {
			setLogInStatus(true)
		}
	}, [auth.user])

	return (
		<HashRouter>
			<div>
				{logInStatus && navbar.show && <Navbar />}
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/Dashboard" element={<Home />} />
					{navbarCategories?.map((category) => {
						return category.subcategories.map((subcategory) => {
							return subcategory.indices.map((index) => {
								const completeIndex = `${category.name.toLowerCase()}-${subcategory.name.toLowerCase()}-${index.name.replace(/ /g, "_").toLowerCase()}`;
								return (<Route key={uuidv4()} path={`/${completeIndex}`} element={<Products key={uuidv4()} indexID={[index._id]} indexPath={completeIndex} />} />);
							});
						});
					})}

					{navbarCategories?.map((category) => {
						return category.subcategories.map((subcategory) => {
							const subcategoryIndicesIds = subcategory.indices.map(index => index._id);
							const completeIndex = `${category.name.toLowerCase()}-${subcategory.name.toLowerCase()}`;
							return (<Route key={uuidv4()} path={`/${completeIndex}`} element={<Products key={uuidv4()} indexID={subcategoryIndicesIds} indexPath={completeIndex} />} />);

						});
					})}
					<Route path="/allProducts" element={<Products key={uuidv4()} indexID={["allProducts"]} indexPath="allProducts" />} />
					<Route path="/Clientes" element={<Clients />} />
					<Route path="/Cliente" element={<Client />} />
					<Route path="/Colaboradores" element={<Collaborators />} />
					<Route path="/Colaborador" element={<Collaborator />} />
					<Route path="/Cotizaciones" element={<Quotation />} />
					<Route path="/Producto" element={<SelectedProduct />} />
					<Route path="/CargaPlanillas" element={<CargaPlanillas />} />
					<Route element={<PrivateNoAdminRoutes />}>
						<Route path="/Comercializacion" element={<Commercialization />} />
						<Route element={<PrivateSuperUserRoutes />}>
							<Route path="/ActoresDeMercado" element={<MarketPlayers />} />
							<Route path="/BuscadorIndex" element={<IndexSearch />} />
							<Route path="/Categorizacion" element={<Categorization />} />
						</Route>
					</Route>
					<Route path="/*" element={<NotFound />} />
				</Routes>
			</div>
		</HashRouter>
	);
}
