import React, { useState } from 'react'
import { useSelector } from "react-redux";

import addIcon from "../../../../assets/svg/functionalities-icons/plus_white.svg";
import infoCircle from "../../../../assets/svg/information-icons/info-circle.svg";
import iconOptions from "../../../../assets/svg/functionalities-icons/tres-puntos-porcentaje-lista.svg";
import iconArrow_gray from "../../../../assets/svg/functionalities-icons/arrow_gray.svg";

import '../commercialization.css'

export const CommercialCondition = ({ setShowConfirmationModal, setShowCreationModal }) => {

    const commercialConditions = useSelector((state) => state.commercialCondition.commercialConditions);

    const [closeSection, setCloseSection] = useState(false)
    const [modalToOpen, setModalToOpen] = useState("");

    const handleUpdateCommercialCondition = (commercialCondition) => {
        setShowCreationModal({
            action: 'Editar',
            type: 'Condición comercial',
            values: commercialCondition
        })
        setModalToOpen("");
    };

    const handleOpenOptions = (commercialCondition) => {
        setModalToOpen(commercialCondition._id);
    };


    const handleLeaveOptions = (className) => {
        if (
            className !== "cuentas-comercial-button_edit" &&
            className !== "cuentas-comercial-button_undo" &&
            className !== "cuentas-comercial-button_options"
        )
            setModalToOpen("");
    };

    return (
        <div className="commercialization-section-container" onClick={(e) => handleLeaveOptions(e.target.className)} style={{ marginTop: "50px" }}>
            <div className="style-section-row" onClick={() => { setCloseSection(!closeSection) }}>
                <h2>Condiciones comerciales</h2>
                <img alt='arrow' src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
            </div>
            <hr />
            <div className={closeSection ? "d-none" : undefined}>
                <section>
                    <img src={infoCircle} alt="info-icon" />
                    <p>
                        La condicion comercial aplica directamente al precio de venta. Solo se puede aplicar una condicion comercial a la vez. El porcentaje aplicado no se muestra
                        a quien no tenga disponible su vista.
                    </p>
                </section>
                <button className="commercialization-add-button" onClick={() => setShowCreationModal({ action: 'Crear', type: 'Condición comercial' })}>
                    <img alt='add' src={addIcon} />
                </button>
                {commercialConditions &&
                    commercialConditions.map((commercialCondition) => {
                        return (
                            <div className="priceList-list-container" key={commercialCondition._id}>
                                <div>
                                    <section>
                                        <h5>{commercialCondition.name}</h5>
                                        <h5>[ {commercialCondition.percentage} % ]</h5>
                                    </section>
                                    <img
                                        src={iconOptions}
                                        alt="options-icon"
                                        className="cuentas-comercial-button_options"
                                        onClick={() => handleOpenOptions(commercialCondition)}
                                    />
                                    {commercialCondition._id === modalToOpen && (
                                        <div className="priceList-list-options_container">
                                            <button className="cuentas-comercial-button_edit" onClick={() => handleUpdateCommercialCondition(commercialCondition)}>
                                                Editar
                                            </button>
                                            <button className="cuentas-comercial-button_delete"
                                                onClick={() => setShowConfirmationModal({ type: "condición comercial", name: commercialCondition.name, id: commercialCondition._id })}>
                                                Eliminar
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div >
    )
}
