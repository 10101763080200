import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const Loader = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				marginTop: "20px",
			}}
		>
			<CircularProgress style={{color: "var(--color-gray-blue)"}}/>
		</Box>
	);
};

export default Loader;
