import React from "react";
// import Filtros from "./filtros/Filtros";
import Categories from "./categories/Categories";

const Categorization = () => {

	document.title = "Scope | Categorización"

	return (
		<>
			{/* <Filtros /> */}
			<Categories />
		</>
	);
};

export default Categorization;
