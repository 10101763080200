import {
	START_GET_QUOTATION,
	GET_QUOTATION_DATA_SUCCESS,
	GET_QUOTATIONS_RECORD_SUCCESS,
	GET_QUOTATION_SUCCESS,
	GET_QUOTATION_STATES_SUCCESS,
	GET_CLIENT_QUOTATIONS_SUCCESS,
	GET_QUOTATION_ERROR,
	CURRENCY_CHANGE,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function addProductToQuotation(product, selectedPriceList, quotationID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			await clienteAxios.put("quotationProductsList",
				{ product, selectedPriceList, quotationID },
				{ headers: { "x-acces-token": cookie.get("token") } }
			);
			dispatch(getQuotationData(quotationID, currency))
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function changeQuotation(clientID, quotationID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			dispatch(getQuotationSuccess({ quotation: quotationID, client: clientID }))
			dispatch(getQuotationData(quotationID, currency))
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function changeQuotationCurrency(quotationID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			await clienteAxios({
				method: "PUT",
				url: `quotationCurrency`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { quotationID, currency }
			});
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function createQuotation(clientID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			const response = await clienteAxios({
				method: "POST",
				url: `quotation`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { clientID, currency }
			});
			dispatch(getQuotationSuccess({ quotation: response.data.response, client: clientID }))
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function deleteQuotationProduct(productID, quotationID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			await clienteAxios({
				method: "DELETE",
				url: `quotationProduct/?productID=${productID}&quotationID=${quotationID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				}
			});
			dispatch(getQuotationData(quotationID, currency));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function deleteQuotationProductsList(quotationID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			await clienteAxios({
				method: "DELETE",
				url: `quotationProductsList/?quotationID=${quotationID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				}
			});
			dispatch(getQuotationData(quotationID, currency));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function getClientsQuotations(clientID, userID) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			const response = await clienteAxios.get(`quotationsList/?clientID=${clientID}&userID=${userID}`,
				{
					headers: { "x-acces-token": cookie.get("token") },
				});
			dispatch(getClientQuotationsSuccess(response.data.response));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function getClientsActiveQuotations(clientID) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			const response = await clienteAxios.get(`activeQuotationsList/?clientID=${clientID}`,
				{
					headers: { "x-acces-token": cookie.get("token") },
				});
			dispatch(getClientQuotationsSuccess(response.data.response));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function getClientsOpenQuotations(clientID) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			const response = await clienteAxios.get(`openQuotationsList/?clientID=${clientID}`,
				{
					headers: { "x-acces-token": cookie.get("token") },
				});
			dispatch(getClientQuotationsSuccess(response.data.response));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function getQuotationData(quotationID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `quotation/?quotationID=${quotationID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				params: { currency }
			});
			dispatch(getQuotationDataSuccess(response.data.response.data));
			dispatch(actionCurrencyChange(response.data.response.currency))
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function getQuotationStates() {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `quotationStates`,
				headers: { "x-acces-token": cookie.get("token") }
			});
			dispatch(getQuotationStatesSuccess(response.data.response));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function getquotationRecord(clientID, collaboratorID, stateID, quantity, startDate, finishDate, isHome) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: 'quotationRecord',
				params: { clientID, collaboratorID, stateID, quantity, startDate, finishDate, isHome },
				headers: { "x-acces-token": cookie.get("token") }
			});
			dispatch(getquotationRecordSuccess(response.data.response));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function updateQuoation(updatedData, quotationID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			await clienteAxios({
				method: "PUT",
				url: `quotation`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { quotationID, updatedData }
			});
			dispatch(getQuotationData(quotationID, currency));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function updateQuotationProduct(updatedData, quotationID, currency) {
	return async (dispatch) => {
		dispatch(startQuotationAction());
		try {
			await clienteAxios({
				method: "PUT",
				url: `quotationProduct`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { quotationID, updatedData }
			});
			dispatch(getQuotationData(quotationID, currency));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

const startQuotationAction = () => ({
	type: START_GET_QUOTATION,
});

const getQuotationDataSuccess = (data) => ({
	type: GET_QUOTATION_DATA_SUCCESS,
	payload: data,
})

const getquotationRecordSuccess = (data) => ({
	type: GET_QUOTATIONS_RECORD_SUCCESS,
	payload: data,
})

const getQuotationStatesSuccess = (list) => ({
	type: GET_QUOTATION_STATES_SUCCESS,
	payload: list,
})

const getQuotationSuccess = (data) => ({
	type: GET_QUOTATION_SUCCESS,
	payload: data,
});

const getClientQuotationsSuccess = (list) => ({
	type: GET_CLIENT_QUOTATIONS_SUCCESS,
	payload: list,
});

const getQuotationError = () => ({
	type: GET_QUOTATION_ERROR,
});

const actionCurrencyChange = (payload) => ({
	type: CURRENCY_CHANGE,
	payload: payload,
});
