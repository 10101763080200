import {
	ACTION_PRODUCTS_START,
	ACTION_PRODUCTS_ERROR,
	GET_PRODUCTS_SUCCESS,
	GET_FILTERS_SUCCESS,
	CURRENCY_CHANGE,
} from "../../types/index";

const initialState = {
	products: [],
	page: 1,
	count: 1,
	isSupplier: true,
	inStock: true,
	currency: "USD",
	minPrice: null,
	maxPrice: null,
	minTotalPrice: null,
	maxTotalPrice: null,
	filters: [],
	suggested: null,
	textSearch: "",
	filterSearch: [],
	isUpdated: false,
	hasPrice: false,
	message: "",
	loading: false,
	error: false,
};

export default function productsReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_PRODUCTS_START:
			return {
				...state,
				error: false,
				loading: true,
			};
		case ACTION_PRODUCTS_ERROR:
			return {
				...state,
				error: true,
				loading: false,
			};
		case GET_PRODUCTS_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				products: action.payload.resp.data,
				count: action.payload.resp.metadata.totalCount,
				page: action.payload.resp.metadata.page,
				minPrice: action.payload.minPrice,
				maxPrice: action.payload.maxPrice,
				minTotalPrice: action.payload.resp.metadata.minTotalPrice,
				maxTotalPrice: action.payload.resp.metadata.maxTotalPrice,
				isSupplier: action.payload.isSupplier,
				inStock: action.payload.inStock,
				suggested: action.payload.resp.suggested,
				textSearch: action.payload.textSearch,
				filterSearch: action.payload.filterSearch,
				isUpdated: action.payload.isUpdated,
				hasPrice: action.payload.hasPrice,
				currency: action.payload.currency
			};
		case GET_FILTERS_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				filters: action.payload,
			};
		case CURRENCY_CHANGE:
			return {
				...state,
				currency: action.payload
			}
		default:
			return state;
	}
}
