import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { moveNavbarIndex, setNavbarCategoryMessage } from "../../../../redux/actions/utilities/navbarAction";

import addIcon from "../../../../assets/svg/functionalities-icons/plus_white.svg";
import iconArrow_gray from "../../../../assets/svg/functionalities-icons/arrow_gray.svg";

import CategoryModal from "./modales/CategoryModal";
import StatusModal from "../../../utilities/Modal/StatusModal";
import SelectIndex from "../../../utilities/SelectIndex";
import { SelectSubcategory } from "../../../utilities/SelectSubcategory";

import "./categories.css";

const Categories = () => {
	const dispatch = new useDispatch();

	const navbarCategories = useSelector((state) => state.navbar);

	const [closeSection, setCloseSection] = useState(false)
	const [closeSection2, setCloseSection2] = useState(false)

	const [openSelectIndex, setOpenSelectIndex] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState({ name: "Indice" });

	const [openSelectSubcategory, setOpenSelectSubcategory] = useState(false)
	const [selectedSubcategory, setSelectedSubcategory] = useState({ name: "Subcategoria" });

	const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
	const [showStatusModal, setShowStatusModal] = useState(false);

	const [existingCategory, setExistingCategory] = useState(null)

	useEffect(() => {
		dispatch(setNavbarCategoryMessage(""))
		setShowStatusModal(false)
	}, [dispatch])

	useEffect(() => {
		if (navbarCategories.message !== "") setShowStatusModal(true)
		else setShowStatusModal(false)
	}, [navbarCategories])

	const handleAddNewCategory = () => {
		setExistingCategory(null)
		setShowAddCategoryModal(true);
	};

	const handleCategorySelect = (elementProduct) => {
		setSelectedIndex(elementProduct);
		setOpenSelectIndex(false);
	};

	const handleEditCategory = (category) => {
		setExistingCategory(category)
		setShowAddCategoryModal(true);
	}

	const handleMoveIndex = () => {
		setOpenSelectIndex(false);
		setOpenSelectSubcategory(false);

		setSelectedIndex({ name: "Indice" });
		setSelectedSubcategory({ name: "Subcategoria" });

		dispatch(moveNavbarIndex(selectedSubcategory._id, selectedIndex.indexID))
	}

	const handleSubcategorySelect = (elementProduct) => {
		setSelectedSubcategory(elementProduct);
		setOpenSelectSubcategory(false);
	};

	return (
		<div className="cuentas-contenedor">
			<div className="style-section-row" onClick={() => { setCloseSection(!closeSection) }}>
				<h2>Categorizacion de items</h2>
				<img alt="arrow" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr />
			<div className={`categories-container${closeSection ? " d-none" : ""}`}>
				{navbarCategories.categories.map((category) => {
					return <div type="button" onClick={() => handleEditCategory(category)} key={category._id}>
						<h4>{category.name}</h4>
						<img alt="phone-icon" src={category.images.desktop} />
					</div>
				})}
				<div type="button" onClick={handleAddNewCategory}>
					<img alt="new" src={addIcon} />
				</div>
			</div>

			<div className="style-section-row" onClick={() => { setCloseSection2(!closeSection2) }}>
				<h2>Reestructuración de categorias</h2>
				<img alt="arrow" src={iconArrow_gray} style={!closeSection2 ? { rotate: "180deg" } : {}} />
			</div>
			<hr />
			<div className={`category-moveIndex-container ${closeSection2 ? " d-none" : ""}`}>
				<div>
					<span
						className="category-moveIndex-selectIndex"
						onClick={() => {
							setOpenSelectIndex(!openSelectIndex);
							setOpenSelectSubcategory(false);
							setSelectedSubcategory({ name: "Subcategoria" })
						}}
					>
						{selectedIndex.name}
						<img src={iconArrow_gray} style={!openSelectIndex ? { rotate: "180deg" } : {}} alt="open" />
					</span>
					<SelectIndex openSelectIndex={openSelectIndex} handleCategorySelect={handleCategorySelect} othersCategory={false} />
				</div>
				<div>
					<span
						className="category-moveIndex-selectIndex"
						onClick={() => {
							setOpenSelectSubcategory(!openSelectSubcategory);
							setOpenSelectIndex(false);
						}}
					>
						{selectedSubcategory.name}
						<img src={iconArrow_gray} style={!openSelectSubcategory ? { rotate: "180deg" } : {}} alt="open" />
					</span>
					<SelectSubcategory openSelectSubcategory={openSelectSubcategory} handleSubcategorySelect={handleSubcategorySelect} subcategoryExcept={selectedIndex.indexID} />
				</div>
				<button disabled={!selectedIndex.tipoProducto || !selectedSubcategory._id} onClick={handleMoveIndex}>Mover indice</button>
			</div>
			{showAddCategoryModal && <CategoryModal setShowAddCategoryModal={setShowAddCategoryModal} existingCategory={existingCategory} />}
			{showStatusModal && (
				<StatusModal loading={navbarCategories.loading} error={navbarCategories.error} setModalStatusCloseAction={setShowStatusModal}>
					{navbarCategories.message}
				</StatusModal>
			)}
		</div>
	);
};

export default Categories;
