import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/utilities/authReducer";
import clientsReducer from "./reducers/entities/clientReducer";
import commercialConditionReducer from "./reducers/utilities/commercialConditionReducer";
import collaboratorsReducer from "./reducers/entities/collaboratorReducer";
import dashboardReducer from "./reducers/dashboard/dashboardReducer";
import currencyReducer from "./reducers/utilities/currencyReducer";
import excelReducer from "./reducers/utilities/excelLoadReducer";
import filtrosReducer from "./reducers/productos/filtrosReducer";
import marketPlayerReducer from "./reducers/entities/marketPlayerReducer";
import navbarReducer from "./reducers/utilities/navbarReducer";
import priceListReducer from "./reducers/utilities/priceListReducer";
import quotationReducer from "./reducers/utilities/quotationReducer";
import productsReducer from "./reducers/utilities/productsReducer";
import productReducer from "./reducers/utilities/productReducer";

const reducer = combineReducers({
	auth: authReducer,
	clients: clientsReducer,
	commercialCondition: commercialConditionReducer,
	collaborators: collaboratorsReducer,
	currency: currencyReducer,
	dashboard: dashboardReducer,
	excel: excelReducer,
	filtros: filtrosReducer,
	marketPlayer: marketPlayerReducer,
	navbar: navbarReducer,
	priceList: priceListReducer,
	product: productReducer,
	products: productsReducer,
	quotation: quotationReducer,
});

const store = createStore(
	reducer,
	compose(
		applyMiddleware(thunk),
		typeof window === "object" && typeof window._REDUX_DEVTOOLS_EXTENSION_ !== "undefined" ? window._REDUX_DEVTOOLS_EXTENSION_() : (f) => f
		// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	)
);

export default store;
