import {
	GET_PRICE_LISTS_ERROR,
	GET_PRICE_LISTS_SUCCESS,
	SET_PRICE_LISTS_MESSAGE,
	START_GET_PRICE_LISTS,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function deletePriceList(id) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `priceList/?id=${id}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setPriceListsMessage(response.data.message))
		} catch (e) {
			dispatch(getPriceListsError());
		}
	};
}

export function getPriceLists() {
	return async (dispatch) => {
		dispatch(downloadPriceLists());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `priceList`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getPriceListsSuccess(response.data.response));
		} catch (e) {
			dispatch(getPriceListsError());
		}
	};
}

export function updatePriceList(name, percentage, id) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: `priceList/?id=${id}&name=${name}&percentage=${percentage}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setPriceListsMessage(response.data.message))
		} catch (e) {
			dispatch(getPriceListsError());
		}
	};
}

export function postPriceList(name, percentage) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "POST",
				url: `priceList/?name=${name}&percentage=${percentage}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setPriceListsMessage(response.data.message));
		} catch (e) {
			dispatch(getPriceListsError());
		}
	};
}

const setPriceListsMessage = (message) => ({
	type: SET_PRICE_LISTS_MESSAGE,
	payload: message
})

const downloadPriceLists = () => ({
	type: START_GET_PRICE_LISTS,
});

const getPriceListsSuccess = (priceLists) => ({
	type: GET_PRICE_LISTS_SUCCESS,
	payload: priceLists,
});

const getPriceListsError = () => ({
	type: GET_PRICE_LISTS_ERROR,
});
