import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { excelLoad } from "../../redux/actions/utilities/excelLoadAction";
import { getMarketPlayers } from "../../redux/actions/entities/marketPlayerActions";

import * as XLSX from "xlsx";

import SelectPlantilla from "./SelectPlanilla";
import SelectIndex from "../utilities/SelectIndex";
import StatusModal from "../utilities/Modal/StatusModal";
import SpreadsheetHistory from "./spreadsheetHistory/SpreadsheetHistory";

import iconArrow_gray from "../../assets/svg/functionalities-icons/arrow_gray.svg";

import { Input, NativeSelect } from '@mantine/core';

import "./cargaPlanillas.css";

const CargaPlanillas = () => {
	const dispatch = useDispatch();

	const marketPlayers = useSelector((state) => state.marketPlayer.marketPlayers);
	const excelReducer = useSelector((state) => state.excel);

	const [closeSection, setCloseSection] = useState(false)

	const [items, setItems] = useState(null);
	const [headers, setHeaders] = useState(null);
	const [fileName, setFileName] = useState("Ningun archivo seleccionado");

	const [openSelect, setOpenSelect] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState({ name: "Categoria" });

	const [dataParsing, setDataParsing] = useState();
	const [titleSelected, setTitleSelected] = useState(false);

	const [otherNameSelected, setOtherNameSelected] = useState(false);
	const [nameSelected, setNameSelected] = useState("");

	const [mostrarModalModificar, setMostrarModalModificar] = useState(false);

	document.title = "Scope | Planillas"

	useEffect(() => {
		if (!mostrarModalModificar) {
			if (!excelReducer.error) {
				setItems(null);
				setFileName("Ningun archivo seleccionado");
				setSelectedCategory({ name: "Categoria" });
				setNameSelected("");
			}
		}
	}, [mostrarModalModificar]);

	const readExcel = (file) => {
		const fileReader = new FileReader();
		if (file) {
			const promise = new Promise((resolve, reject) => {
				fileReader.readAsArrayBuffer(file);

				fileReader.onload = (e) => {
					const bufferArray = e.target.result;
					const wb = XLSX.read(bufferArray, { type: "buffer" });
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];
					const data = XLSX.utils.sheet_to_json(ws);
					const columns = XLSX.utils.sheet_to_json(ws, {
						header: 1,
					});

					setHeaders(columns[0]);
					resolve(data);
				};

				fileReader.onerror = (error) => {
					reject(error);
				};
			});

			promise.then((data) => {
				setItems(data);
				setFileName(file.name);
			});
			dispatch(getMarketPlayers());
		}
	};

	const handleCategorySelect = (elementProduct) => {
		setSelectedCategory(elementProduct);
		setOpenSelect(false);
	};

	const handleSelectInput = (evento, etiqueta) => {
		let flag = true;

		if (etiqueta === "title") setTitleSelected(true);
		if (etiqueta === "nombre" && evento.target.value === "otro") {
			setOtherNameSelected(true);
			setNameSelected("");
		} else if (etiqueta === "nombre" && !evento.nativeEvent.data && evento.nativeEvent.inputType !== "deleteContentBackward") {
			setNameSelected(evento.target.value);
			setOtherNameSelected(false);
		}

		dataParsing &&
			dataParsing.map((llave, index) => {
				if (llave.tag === etiqueta) {
					dataParsing[index].etiqueta = evento.target.value;
					flag = false;
				}
				return null
			});

		if (flag && evento.target.value !== 'Dejar campo vacio')
			setDataParsing(
				dataParsing ? [...dataParsing, { tag: etiqueta, etiqueta: evento.target.value }] : [{ tag: etiqueta, etiqueta: evento.target.value }]
			);

		if (evento.nativeEvent.srcElement.id !== 'company-input-name' && evento.nativeEvent.srcElement.id !== 'companyName') {
			var select = document.getElementById(evento.nativeEvent.srcElement.id);
			var selectedOption = []

			if (evento.target.value === 'Dejar campo vacio') {
				selectedOption = select.options[select.selectedIndex];
				selectedOption.text = "Seleccionar";
				selectedOption.className = "d-none"
			} else {
				selectedOption = select.options[select.options.length - 1];
				selectedOption.text = "Dejar campo vacio";
				selectedOption.className = ""
			}
		}
	};


	const handleNameChange = (e) => {
		setNameSelected(e.target.value);
		dataParsing.map((llave, index) => {
			if (llave.tag === "nombre") {
				dataParsing[index].etiqueta = e.target.value;
			}
			return null
		});

	};

	async function handleSubmitExcel() {
		const cleanData = dataParsing.filter((data) => {
			if (data.etiqueta !== 'Dejar campo vacio') return data
			return null
		})
		setDataParsing(cleanData)
		setMostrarModalModificar(true);
		const response = await dispatch(excelLoad(selectedCategory.indexID, selectedCategory.name, items, cleanData, fileName));
		if (response) {
			setItems(null);
			setFileName("Ningun archivo seleccionado");
		}
	}

	return (
		<>
			<div className="cuentas-contenedor carga-planillas-contenedor">
				<div className="style-section-row" onClick={() => { setCloseSection(!closeSection) }}>
					<h2>Carga de planillas</h2>
					<img alt="arrow" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
				</div>
				<hr />

				<div className={closeSection ? "d-none" : undefined}>

					<div className="cargar-planillas-fileSelect">
						<input
							id="fileSelect"
							type="file"
							accept=".xlsx"
							onChange={(e) => {
								readExcel(e.target.files[0]);
							}}
							onClick={(e) => {
								readExcel(e.target.files[0]);
							}}
						/>
						<label htmlFor="fileSelect">
							<span>Cargar</span>
							<span>{fileName}</span>
						</label>
					</div>

					<span
						onClick={() => {
							setOpenSelect(!openSelect);
						}}
					>
						{selectedCategory.name}
						<img src={iconArrow_gray} style={!openSelect ? { rotate: "180deg" } : {}} alt="open" />
					</span>

					<SelectIndex openSelectIndex={openSelect} handleCategorySelect={handleCategorySelect} othersCategory={true} />

					{items && (
						<>
							<div>
								<section onChange={(e) => handleSelectInput(e, "nombre")}>
									<label htmlFor="companyName">* Nombre empresa:</label>
									<NativeSelect size="xs" id="companyName">
										<option hidden>Seleccionar</option>
										{marketPlayers.length && marketPlayers.map((marketPlayer) => {
											return (
												<option value={marketPlayer.company.name} key={marketPlayer.company.name} style={{ textTransform: 'capitalize' }}>
													{marketPlayer.company.name}
												</option>
											);
										})}
										<option value="otro">Otro</option>
									</NativeSelect>
									{otherNameSelected && (
										<Input size="xs"
											id="company-input-name"
											placeholder="Introduzca nombre"
											style={{ background: 'white !important' }}
											onChange={(e) => handleNameChange(e)}
											value={nameSelected}
										/>
									)}
								</section>

								<section onChange={(e) => handleSelectInput(e, "esProveedor")}>
									<label htmlFor="supplier">Proveedor:</label>
									<NativeSelect size="xs" id="supplier">
										<option hidden>Seleccionar</option>
										<option value={true}>Verdadero</option>
										<option value={false}>Falso</option>
										<option className="d-none">Dejar campo vacio</option>
									</NativeSelect>
								</section>

								<SelectPlantilla etiqueta="title" label="* Titulo" headers={headers} handleSelectInput={handleSelectInput} />
								<SelectPlantilla etiqueta="brand" label="Marca" headers={headers} handleSelectInput={handleSelectInput} />
								<SelectPlantilla etiqueta="partNumber" label="Numero de parte" headers={headers} handleSelectInput={handleSelectInput} />
								<SelectPlantilla etiqueta="informacionTotal" label="Descripcion" headers={headers} handleSelectInput={handleSelectInput} />
								<SelectPlantilla etiqueta="precioContado" label="Precio de contado" headers={headers} handleSelectInput={handleSelectInput} />
								<SelectPlantilla etiqueta="precioLista" label="Precio de lista" headers={headers} handleSelectInput={handleSelectInput} />

								<section onChange={(e) => handleSelectInput(e, "tipoMoneda")}>
									<label htmlFor="currency">Moneda:</label>
									<NativeSelect size="xs" id="currency">
										<option hidden>Seleccionar</option>
										<option value="ARS">ARS</option>
										<option value="USD">USD</option>
										<option className="d-none">Dejar campo vacio</option>
									</NativeSelect>
								</section>

								<SelectPlantilla etiqueta="ivaProducto" label="IVA" headers={headers} handleSelectInput={handleSelectInput} />
								<SelectPlantilla etiqueta="impInterProducto" label="Impuesto interno" headers={headers} handleSelectInput={handleSelectInput} />
								<SelectPlantilla etiqueta="stock" label="Stock" headers={headers} handleSelectInput={handleSelectInput} />
							</div>
							<button
								disabled={selectedCategory.name === "Categoria" || !titleSelected || nameSelected === ""}
								onClick={handleSubmitExcel}
							>
								Confirmar planilla
							</button>

							{mostrarModalModificar && (
								<StatusModal loading={excelReducer.loading} error={excelReducer.error} setModalStatusCloseAction={setMostrarModalModificar}>
									{excelReducer.loading
										? "Verificando informacion..."
										: excelReducer.error
											? "No se pudo cargar la planilla"
											: "Se cargó la planilla correctamente"}
								</StatusModal>
							)}
						</>
					)}
				</div>
			</div>
			<SpreadsheetHistory />
		</>
	);
};

export default CargaPlanillas;
