import React, { useState } from "react";

import { Input, Modal } from '@mantine/core';

export default function ModalContact({ handleContactAction, showContactModal, setShowContactModal }) {

    const [nameInput, setNameInput] = useState(showContactModal.data ? showContactModal.data.name : "")
    const [lastNameInput, setLastNameInput] = useState(showContactModal.data ? showContactModal.data.lastName : "")
    const [emailInput, setEmailInput] = useState(showContactModal.data ? showContactModal.data.email : "")
    const [phoneInput, setPhoneInput] = useState(showContactModal.data ? showContactModal.data.phone : "")

    return (
        <Modal
            centered
            title={`${showContactModal.action} contacto`}
            opened={true}
            onClose={() => { setShowContactModal(false) }}
            overlayProps={{
                backgroundOpacity: 0.5,
                blur: 3,
                zIndex: 1100
            }}
        >
            <div className="entitiy-addModal-container">
                <section>
                    <Input id="nameInput" placeholder="Nombre *" value={nameInput} onChange={(e) => setNameInput(e.target.value)} />
                    <Input disabled={!nameInput} id="lastNameInput" placeholder="Apellido *" value={lastNameInput} onChange={(e) => setLastNameInput(e.target.value)} />
                </section>
                <section>
                    <Input disabled={!lastNameInput} id="emailInput" placeholder="Email" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} />
                </section>
                <section>
                    <Input disabled={!lastNameInput} id="phoneInput" placeholder="Teléfono" value={phoneInput} onChange={(e) => setPhoneInput(e.target.value)} />
                </section>
                <button className="modales-modal_editar-boton modales-modal_editar-btn_editar"
                    disabled={!emailInput && !phoneInput}
                    onClick={() => handleContactAction(nameInput, lastNameInput, emailInput, phoneInput)} >
                    Confirmar
                </button>
            </div>
        </Modal>
    );
}
