import React, { useState } from 'react'

import { Modal, Box } from "@mui/material";

import closeModalIcon from "../../../../assets/svg/functionalities-icons/cross_gray.svg";

import { modalBoxStyle } from "../../../utilities/utilities";

import './commercializationCreationModal.css'

export default function CommercializationCreationModal({ handlerCreationModalAction, showCreationModal, setShowCreationModal }) {

    const [nameValue, setNameValue] = useState(showCreationModal.values?.name || "")
    const [percentageValue, setPercentageValue] = useState(showCreationModal.values?.percentage || "")

    return (
        <Modal
            open={true}
            onClose={() => { setShowCreationModal(false) }}
            style={{ backdropFilter: "blur(4px)" }}
        >
            <Box sx={modalBoxStyle} style={{ width: '292px' }}>
                <div className="creationModal-container">
                    <div >
                        <h4 className="modales-titulo_modal">{showCreationModal.action} {showCreationModal.type.toLowerCase()}</h4>
                        <img
                            src={closeModalIcon}
                            className="modales-cerrar_modal-img"
                            alt="close"
                            onClick={() => { setShowCreationModal(false) }}
                        />
                    </div>

                    <section>
                        <input id="name-input" placeholder="Nombre" value={nameValue} onChange={(e) => setNameValue(e.target.value)} />
                        <input id="percentage-input" placeholder="Porcentaje" type='number' min='0' value={percentageValue} onChange={(e) => setPercentageValue(e.target.value)} />
                    </section>

                    <button disabled={!nameValue || !percentageValue} onClick={() => handlerCreationModalAction(nameValue, percentageValue)}>
                        Confirmar
                    </button>
                </div>
            </Box>
        </Modal>
    )
}
