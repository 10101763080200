import {
	START_GET_QUOTATION,
	GET_QUOTATION_DATA_SUCCESS,
	GET_QUOTATIONS_RECORD_SUCCESS,
	GET_QUOTATION_SUCCESS,
	GET_QUOTATION_STATES_SUCCESS,
	GET_CLIENT_QUOTATIONS_SUCCESS,
	GET_QUOTATION_ERROR,
	RESET_QUOTATION_RECORD
} from "../../types/index";

const initialState = {
	client: null,
	data: null,
	error: false,
	list: null,
	loading: false,
	quotation: null,
	record: [],
	states: null,
};

export default function quotationReducer(state = initialState, action) {
	switch (action.type) {
		case GET_QUOTATION_ERROR:
			return {
				...state,
				error: true,
				loading: false,
			};
		case GET_CLIENT_QUOTATIONS_SUCCESS:
			return {
				...state,
				error: false,
				list: action.payload,
				loading: false,
			}
		case GET_QUOTATION_DATA_SUCCESS:
			return {
				...state,
				data: action.payload,
				error: false,
				loading: false,
			}
		case GET_QUOTATION_SUCCESS:
			return {
				...state,
				client: action.payload.client,
				error: false,
				loading: false,
				quotation: action.payload.quotation,
			};
		case GET_QUOTATION_STATES_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				states: action.payload
			}
		case START_GET_QUOTATION:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_QUOTATIONS_RECORD_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				record: action.payload,
			}
		case RESET_QUOTATION_RECORD:
			return {
				...state,
				record: [],
			}
		default:
			return state;
	}
}
