import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { hideNavBar } from "../../redux/actions/utilities/navbarAction";
import { logInUser } from "../../redux/actions/utilities/authAction";

import universlaCookie from "universal-cookie";

// import ilustracionLogin from "../../assets/svg/trendit-images/logo_trendit.svg";
import logoAxonScopeVertical from "../../assets/svg/axonscope/axonscope_vertical.svg";
// import ilustracionReforestar from "../../assets/svg/trendit-images/logo_reforestar.svg";
// import ilustracionCertificado from "../../assets/svg/trendit-images/logo_certificado.svg";

import ModalModificarContrasenia from "./ModalModificarContrasenia";
import ModalRecoverPassword from "./ModalRecoverPassword"

import "./login.css";

const Login = () => {
	const dispatch = useDispatch();
	const navegation = useNavigate();
	const cookie = new universlaCookie();

	const message = useSelector((state) => state.auth.message);
	const claveProvisoria = useSelector((state) => state.auth.claveProvisoria);

	const [showModalRecoverPassword, setShowModalRecoverPassword] = useState(false);
	const [showModalChangePassword, setShowModalChangePassword] = useState(true);

	const [emailInput, setEmailInput] = useState(cookie.get("user") || "");
	const [passwordSignIn, setPasswordSignIn] = useState(cookie.get("password") || "");

	useEffect(() => {
		if (cookie.get("token")) {
			navegation("/Dashboard")
		} else {
			dispatch(hideNavBar());
			document.title = "Scope | Login"
		}
	}, []);

	const handleSubmitSignIn = async (e) => {
		e.preventDefault();
		await dispatch(logInUser(emailInput.toLowerCase(), passwordSignIn));
		if (localStorage.getItem("LogInStatus") === "true") navegation("/Dashboard");
	};

	const handleSpace = (e) => {
		if (e.nativeEvent.data !== " ") setPasswordSignIn(e.target.value);
	};

	const handleEmailInput = (event) => {
		if (event.nativeEvent.data !== " ") setEmailInput(event.target.value);
	}

	return (
		<div className="cuentas-contenedor login-container">
			<div className="login-contenedor-inputs">
				<h3 className="login-titulo">¡Bienvenido!</h3>
				<div className="login-contenedor_imagen">
					<img className="login-imagen" src={logoAxonScopeVertical} alt="login" />
					{/* <div className="login-logos_menores">
						<img src={ilustracionCertificado} alt="certificado" />
						<div className="linea-separadora-signIn" />
						<img src={ilustracionReforestar} alt="reforestar" />
					</div> */}
				</div>
				<div className="linea-separadora-signIn" />
				<div className="login-formulario-contenedor">
					<h3 className="login-titulo">¡Bienvenido!</h3>
					<form onSubmit={handleSubmitSignIn} autoComplete="off">
						{message && <h5>{message}</h5>}
						<input
							autoComplete="username"
							name="input username"
							onChange={(e) => { handleEmailInput(e) }}
							placeholder="Email *"
							required
							value={emailInput}
						/>
						<input
							autoComplete="current-password"
							name="input password"
							minLength={8}
							maxLength={12}
							onChange={(e) => handleSpace(e)}
							placeholder="Contraseña *"
							required
							type="password"
							value={passwordSignIn}
						/>
						<div className="login-lable_contenedor">
							<h4 type="button" onClick={() => setShowModalRecoverPassword(true)}>
								¿Olvidaste tu contraseña?
							</h4>
						</div>
						<button disabled={!emailInput || !passwordSignIn}>INICIAR SESIÓN</button>
					</form>
				</div>
			</div>
			{showModalRecoverPassword && <ModalRecoverPassword setShowModalRecoverPassword={setShowModalRecoverPassword} />}
			{claveProvisoria && showModalChangePassword && <ModalModificarContrasenia emailInput={emailInput} setShowModalChangePassword={setShowModalChangePassword} />}
		</div>
	);
};
export default Login;