import {
    GET_COMMERCIAL_CONDITION_ERROR,
    GET_COMMERCIAL_CONDITION_SUCCESS,
    SET_COMMERCIAL_CONDITION_MESSAGE,
    START_GET_COMMERCIAL_CONDITION,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function deleteCommercialCondition(id) {
    return async (dispatch) => {
        try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `commercialCondition/?id=${id}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
            dispatch(setCommercialConditionMessage(response.data.message))
        } catch (e) {
            dispatch(getCommercialConditionsError());
        }
    };
}

export function getCommercialConditions() {
    return async (dispatch) => {
        dispatch(downloadCommercialConditions());
        try {
			const response = await clienteAxios({
				method: "GET",
				url: `commercialCondition`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
            dispatch(getCommercialConditionsSuccess(response.data.response));
        } catch (e) {
            dispatch(getCommercialConditionsError());
        }
    };
}

export function updateCommercialCondition(name, percentage, id) {
    return async (dispatch) => {
        try {
			const response = await clienteAxios({
				method: "PUT",
				url: `commercialCondition/?id=${id}&name=${name}&percentage=${percentage}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
            dispatch(setCommercialConditionMessage(response.data.message))
        } catch (e) {
            dispatch(getCommercialConditionsError());
        }
    };
}

export function postCommercialCondition(name, percentage) {
    return async (dispatch) => {
        try {
			const response = await clienteAxios({
				method: "POST",
				url: `commercialCondition/?name=${name}&percentage=${percentage}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
            dispatch(setCommercialConditionMessage(response.data.message));
        } catch (e) {
            dispatch(getCommercialConditionsError());
        }
    };
}

const setCommercialConditionMessage = (message) => ({
    type: SET_COMMERCIAL_CONDITION_MESSAGE,
    payload: message
})

const downloadCommercialConditions = () => ({
    type: START_GET_COMMERCIAL_CONDITION,
});

const getCommercialConditionsSuccess = (commercialConditions) => ({
    type: GET_COMMERCIAL_CONDITION_SUCCESS,
    payload: commercialConditions,
});

const getCommercialConditionsError = () => ({
    type: GET_COMMERCIAL_CONDITION_ERROR,
});