export const ACTION_LOGIN_START = "ACTION_LOGIN_START";
export const ACTION_LOGIN_ERROR = "ACTION_LOGIN_ERROR";
export const ACTION_AUTH_SUCCESS = "ACTION_AUTH_SUCCESS";

export const LOG_IN_USER_EXITO = "LOG_IN_USER_EXITO";
export const CONTRASENIA_PROVISORIA = "CONTRASENIA_PROVISORIA";
export const RESETEAR_CLAVE_PROVISORIA = "RESETEAR_CLAVE_PROVISORIA";
export const MODIFICAR_CREAR_CLAVE_CUENTA_EXITO = "MODIFICAR_CREAR_CLAVE_CUENTA_EXITO";
export const RESETEAR_ESTADO_REDUX_CUENTAS = "RESETEAR_ESTADO_REDUX_CUENTAS";

export const RECUPERAR_CONTRASEÑIA_USER_ERROR = "RECUPERAR_CONTRASEÑIA_USER_ERROR";

export const AUTHENTICATION = "AUTHENTICATION";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";