import {
	ACTION_MARKET_PLAYER_ERROR,
	ACTION_MARKET_PLAYER_RESET,
	ACTION_MARKET_PLAYER_START,
	ACTION_MARKET_PLAYER_SUCCESS,
	GET_MARKET_PLAYER_SUCCESS,
	GET_PRODUCT_INDICES_SUCCESS,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function addMarketPlayer(isSupplier, name) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const response = await clienteAxios({
				method: "POST",
				url: "marketPlayer",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { isSupplier, name },
			});
			dispatch(actionMarketPlayerSuccess(response.data.message))
			dispatch(getMarketPlayers());
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function addProductIndex(object, marketPlayerID) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const response = await clienteAxios({
				method: "POST",
				url: "productIndex",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: { object, marketPlayerID },
			});
			dispatch(actionMarketPlayerSuccess(response.data.message))
			dispatch(getProductIndices(marketPlayerID));
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function deleteMarketPlayer(marketPlayer) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `marketPlayer/?marketPlayerID=${marketPlayer._id}&marketPlayerName=${marketPlayer.company.name}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(actionMarketPlayerSuccess(response.data.message));
			dispatch(getMarketPlayers());
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function deleteProductIndex(marketPlayerID, productIndexID) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `productIndex/?productIndexID=${productIndexID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(actionMarketPlayerSuccess(response.data.message));
			dispatch(getProductIndices(marketPlayerID));
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function enableMarketPlayer(marketPlayerID) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			await clienteAxios({
				method: "PUT",
				url: `toggleIsEnabledMarketPlayer/?marketPlayerID=${marketPlayerID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getMarketPlayers());
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function getMarketPlayer(marketPlayerID) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const respuesta = await clienteAxios({
				method: "GET",
				url: `marketPlayer/?marketPlayerID=${marketPlayerID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getMarketPlayerSuccess(respuesta.data.response));
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function getMarketPlayers() {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const respuesta = await clienteAxios({
				method: "GET",
				url: `marketPlayers`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getMarketPlayerSuccess(respuesta.data.response));
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function getProductIndices(marketPlayerID) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const respuesta = await clienteAxios({
				method: "GET",
				url: `productIndex/?marketPlayerID=${marketPlayerID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getProductIndicesSuccess(respuesta.data.response));
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function updateMarketPlayer(marketPlayerID, updatedUsername, updatedPassword) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: "marketPlayer",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: {
					marketPlayerID,
					updatedUsername,
					updatedPassword,
				},
			});
			dispatch(actionMarketPlayerSuccess(response.data.message))
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

export function updateProductIndex(marketPlayerID, object, selectedIndex) {
	return async (dispatch) => {
		dispatch(actionMarketPlayerStart());
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: "productIndex",
				headers: { "x-acces-token": cookie.get("token"), },
				data: { object, selectedIndex },
			});
			dispatch(getProductIndices(marketPlayerID));
			dispatch(actionMarketPlayerSuccess(response.data.message));
		} catch (error) {
			dispatch(actionMarketPlayerError(error.response.data.message));
		}
	};
}

const actionMarketPlayerError = (message) => ({
	type: ACTION_MARKET_PLAYER_ERROR,
	payload: message
});

export const actionMarketPlayerRestart = () => ({
	type: ACTION_MARKET_PLAYER_RESET
})

const actionMarketPlayerStart = () => ({
	type: ACTION_MARKET_PLAYER_START,
});

const actionMarketPlayerSuccess = (message) => ({
	type: ACTION_MARKET_PLAYER_SUCCESS,
	payload: message
})

const getMarketPlayerSuccess = (response) => ({
	type: GET_MARKET_PLAYER_SUCCESS,
	payload: response,
});

const getProductIndicesSuccess = (response) => ({
	type: GET_PRODUCT_INDICES_SUCCESS,
	payload: response
});