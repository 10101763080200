import React, { useState } from "react";
import { Input, Modal } from '@mantine/core';

export default function ModalDirection({ handleDirectionAction, showDirectionModal, setShowDirectionModal }) {

    const [countryInput, setCountryInput] = useState(showDirectionModal.data ? showDirectionModal.data.country : "")
    const [provinceInput, setProvinceInput] = useState(showDirectionModal.data ? showDirectionModal.data.province : "")
    const [localityInput, setLocalityInput] = useState(showDirectionModal.data ? showDirectionModal.data.locality : "")
    const [neighborhoodInput, setNeighborhoodInput] = useState(showDirectionModal.data ? showDirectionModal.data.neighborhood : "")
    const [streetInput, setStreetInput] = useState(showDirectionModal.data ? showDirectionModal.data.street : "")
    const [numberInput, setNumberInput] = useState(showDirectionModal.data ? showDirectionModal.data.number : "")
    const [floorInput, setFloorInput] = useState(showDirectionModal.data ? showDirectionModal.data.floor : "")
    const [postalCodeInput, setPostalCodeInput] = useState(showDirectionModal.data ? showDirectionModal.data.postalCode : "")

    return (
        <Modal
            centered
            title={`${showDirectionModal.action} dirección`}
            opened={true}
            onClose={() => { setShowDirectionModal(false) }}
            overlayProps={{
                backgroundOpacity: 0.5,
                blur: 3,
                zIndex: 1100
            }}
        >
            <div className="entitiy-addModal-container">
                <section>
                    <Input id="countryInput" placeholder="País *" value={countryInput} onChange={(e) => setCountryInput(e.target.value)} />
                    <Input disabled={!countryInput} id="provinceInput" placeholder="Provincia *" value={provinceInput} onChange={(e) => setProvinceInput(e.target.value)} />
                </section>
                <section>
                    <Input disabled={!countryInput || !provinceInput} id="localityInput" placeholder="Localidad *" value={localityInput} onChange={(e) => setLocalityInput(e.target.value)} />
                    <Input disabled={!countryInput || !provinceInput} id="neighborhoodInput" placeholder="Barrio *" value={neighborhoodInput} onChange={(e) => setNeighborhoodInput(e.target.value)} />
                </section>
                <section>
                    <Input disabled={!countryInput || !provinceInput} id="postalCodeInput" placeholder="Código postal" value={postalCodeInput} onChange={(e) => setPostalCodeInput(e.target.value)} />
                    <Input disabled={!countryInput || !provinceInput} id="streetInput" placeholder="Calle *" value={streetInput} onChange={(e) => setStreetInput(e.target.value)} />
                </section>
                <section>
                    <Input disabled={!streetInput} id="numberInput" placeholder="Numero *" value={numberInput} onChange={(e) => setNumberInput(e.target.value)} />
                    <Input disabled={!streetInput} id="floorInput" placeholder="Piso/Dpto." value={floorInput} onChange={(e) => setFloorInput(e.target.value)} />
                </section>
                <button className="modales-modal_editar-boton modales-modal_editar-btn_editar"
                    disabled={!countryInput || !provinceInput || !streetInput || !numberInput}
                    onClick={() => handleDirectionAction(countryInput, provinceInput, localityInput, neighborhoodInput, streetInput, numberInput, floorInput, postalCodeInput)}  >
                    Confirmar
                </button>
            </div>
        </Modal>
    );
}