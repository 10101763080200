import {
	ACTION_LOGIN_ERROR,
	ACTION_LOGIN_START,
	ACTION_AUTH_SUCCESS,
	AUTHENTICATION,
	LOG_IN_USER_EXITO,
	LOGOUT_SUCCESS,
	RECUPERAR_CONTRASEÑIA_USER_ERROR,
	MODIFICAR_CREAR_CLAVE_CUENTA_EXITO,
	CONTRASENIA_PROVISORIA,
	RESETEAR_CLAVE_PROVISORIA,
} from "../../types";
import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";
import { showNavBar } from "./navbarAction";
const cookie = new universlaCookie();

export function authUser() {
	return async (dispatch) => {
		dispatch(actionLoginStart());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: "user",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(authenticationSuccess(response.data.data))
			dispatch(showNavBar())
		} catch {
			dispatch(authActionSuccess(""))
		}
	};
}

export function logInUser(userInput, passEncriptado) {
	return async (dispatch) => {
		dispatch(actionLoginStart());
		try {
			const response = await clienteAxios({
				method: "POST",
				url: "signInUser",
				data: {
					email: userInput,
					password: passEncriptado,
				},
			});
			if (response.data.message === "Acceso Provisorio") {
				dispatch(claveProvisoriaExito());
			} else {
				guardarTokenEnCookie(response.data);
				dispatch(signInExito(response.data));
			}
			dispatch(showNavBar())
		} catch (error) {
			dispatch(actionLoginError(error.response.data.message));
		}
	};
}

export function resetearEstadoErrorUser() {
	return async (dispatch) => {
		dispatch(actionLogoutSuccess());
	};
}

export function resetearClaveProvisoria() {
	return async (dispatch) => {
		dispatch(resetearClaveProv());
	};
}

export function recuperarContrasenia(emailEncriptado) {
	return async (dispatch) => {
		dispatch(actionLoginStart());
		try {
			const response = await clienteAxios({
				method: "POST",
				url: "passwordReset",
				data: { email: emailEncriptado },
			});
			dispatch(authActionSuccess(response.data));
		} catch (error) {
			dispatch(recuperacionContraseniaError(error.response.data.message));
		}
	};
}

export function modificarClaveCuenta(email, newPassword) {
	return async (dispatch) => {
		dispatch(actionLoginStart());
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: "changeCollaboratorPassword",
				data: {
					email: email,
					newPassword: newPassword,
				},
			});
			guardarTokenEnCookie(response.data);
			dispatch(signInExito(response.data));
			dispatch(modificarClaveExito(response.data));
		} catch (error) {
			dispatch(actionLoginError(error.response.data.message));
		}
	};
}

export function logOutUser() {
	return (dispatch) => {
		dispatch(actionLoginStart());
		try {
			dispatch(actionLogoutSuccess());
		} catch (error) {
			dispatch(actionLoginError(error.response.data.message));
		}
	};
}

function guardarTokenEnCookie(response) {
	localStorage.setItem("LogInStatus", true);
	cookie.set("token", response.token);
};

const actionLoginStart = () => ({
	type: ACTION_LOGIN_START,
});

const actionLoginError = (message) => ({
	type: ACTION_LOGIN_ERROR,
	payload: message
});

const actionLogoutSuccess = () => ({
	type: LOGOUT_SUCCESS
})

const signInExito = (response) => ({
	type: LOG_IN_USER_EXITO,
	payload: response,
});

const claveProvisoriaExito = () => ({
	type: CONTRASENIA_PROVISORIA,
});

const resetearClaveProv = () => ({
	type: RESETEAR_CLAVE_PROVISORIA,
});

const recuperacionContraseniaError = (message) => ({
	type: RECUPERAR_CONTRASEÑIA_USER_ERROR,
	payload: message,
});

const modificarClaveExito = (cuentas) => ({
	type: MODIFICAR_CREAR_CLAVE_CUENTA_EXITO,
	payload: cuentas,
});

const authActionSuccess = (response) => ({
	type: ACTION_AUTH_SUCCESS,
	payload: response,
})

const authenticationSuccess = (response) => ({
	type: AUTHENTICATION,
	payload: response,
})