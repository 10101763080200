import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getProducts } from "../../../redux/actions/productos/productsAction"

import FiltroTarjeta from "./FiltroTarjeta";

import {
	Accordion, NativeSelect, Switch,
	//  RangeSlider 
} from '@mantine/core';

import "./filtrosItems.css";

export default function Filters({ productIndex, setSelectedPricelist }) {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const currency = useSelector((state) => state.currency.data);
	const productsReducer = useSelector((state) => state.products);
	const priceLists = useSelector((state) => state.priceList.priceList);

	// const [minValue, setMinValue] = useState(searchParams.get('minPrice') !== null ? parseFloat(searchParams.get('minPrice')) : productsReducer.minTotalPrice);
	// const [maxValue, setMaxValue] = useState(searchParams.get('maxPrice') !== null ? parseFloat(searchParams.get('maxPrice')) : productsReducer.maxTotalPrice);
	const [supplierCheck, setSupplierCheck] = useState(searchParams.get('isSupplier') !== null ? searchParams.get('isSupplier') === "true" : true);

	const [stockCheck, setStockCheck] = useState(searchParams.get('inStock') !== null ? searchParams.get('inStock') === "true" : true)

	const [totalFiltros, setTotalFiltros] = useState([]);

	useEffect(() => {
		if (productsReducer.filters?.length > 0) {
			setTotalFiltros(
				productsReducer.filters.map((filtroItemMap) => {
					return {
						...filtroItemMap,
						labels: filtroItemMap.labels.map((label) => {
							return { ...label, check: false };
						}),
					};
				})
			);
		} else {
			setTotalFiltros([]);
		}
	}, [productsReducer.filters]);

	useEffect(() => {
		if (productsReducer.filters?.length > 0) {
			setTotalFiltros(
				productsReducer.filters.map((filtroItemMap) => {
					return {
						...filtroItemMap,
						labels: filtroItemMap.labels.map((label) => {
							return { ...label, check: productsReducer.filterSearch.includes(label.value) };
						}),
					};
				})
			);
		} else {
			setTotalFiltros([]);
		}
	}, [productsReducer.filterSearch, searchParams]);


	// const formatearNumero = (valor) => {
	// 	let valorARetornarAuxiliar = new Intl.NumberFormat("de-DE").format((valor * 1).toFixed(2));
	// 	return "$ " + valorARetornarAuxiliar;
	// };

	const toggleInStock = () => {
		dispatch(getProducts(productIndex, { ...productsReducer, inStock: !productsReducer.inStock, page: 1 }));
		searchParams.set("inStock", !productsReducer.inStock);
		searchParams.set("page", 1);
		setStockCheck(!stockCheck)
		setSearchParams(searchParams);
	}

	const toggleIsUpdated = () => {
		dispatch(getProducts(productIndex, { ...productsReducer, isUpdated: !productsReducer.isUpdated, page: 1 }));
		searchParams.set("isUpdated", !productsReducer.isUpdated);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const toggleHasPrice = () => {
		dispatch(getProducts(productIndex, { ...productsReducer, hasPrice: !productsReducer.hasPrice, page: 1 }));
		searchParams.set("hasPrice", !productsReducer.hasPrice);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const cambiarFiltros = (filtroNuevo) => {
		setTotalFiltros(
			totalFiltros.map((filtroMap) => {
				if (filtroMap._id === filtroNuevo._id) {
					return filtroNuevo;
				}
				return filtroMap;
			})
		);

		let filterSearch = productsReducer.filterSearch ? productsReducer.filterSearch : []
		filtroNuevo.labels.map(label => {
			if (label.check) {
				if (!filterSearch.includes(label.value)) {
					filterSearch.push(label.value);
				}
			} else {
				filterSearch = filterSearch.filter(item => item !== label.value);
			}
		})

		dispatch(getProducts(productIndex, { ...productsReducer, filterSearch: filterSearch, page: 1 }));

		filterSearch = filterSearch.map(item => item.replace(" ", "_"));
		searchParams.set("filterSearch", filterSearch.join(" "));
		setSearchParams(searchParams);
	};


	// const handleSlide = (e) => {
	// 	setMinValue(e[0]);
	// 	setMaxValue(e[1]);
	// };

	// const handleLimitsChange = (e) => {
	// 	setMinValue(e[0]);
	// 	setMaxValue(e[1]);

	// 	dispatch(getProducts(productIndex, { ...productsReducer, minPrice: e[0], maxPrice: e[1], page: 1 }));
	// 	searchParams.set("minPrice", e[0]);
	// 	searchParams.set("maxPrice", e[1]);
	// 	searchParams.set("page", 1);
	// 	setSearchParams(searchParams);
	// }

	const handleCurrencyChange = (value) => {
		dispatch(getProducts(productIndex, { ...productsReducer, currency: value }));
	}

	const handleIsSupplierChange = (newIsSupplier) => {
		dispatch(getProducts(productIndex, { ...productsReducer, isSupplier: newIsSupplier, page: 1 }));
		setSupplierCheck(newIsSupplier);
		searchParams.set("page", 1);
		searchParams.set("isSupplier", newIsSupplier);
		setSearchParams(searchParams);
	}

	return (
		<Accordion variant="contained" className="filters-container" defaultValue="filters">
			<Accordion.Item key={1} value="filters">
				<Accordion.Control>FILTROS</Accordion.Control>
				<Accordion.Panel>
					<NativeSelect
						label="Lista de precio seleccionada"
						onChange={(e) => {
							setSelectedPricelist(priceLists.find((priceList) => priceList._id === e.target.value));
						}}
						size="xs"
					>
						{priceLists.map((priceList) => {
							return (
								<option value={priceList._id} key={priceList._id}>
									{priceList.name}
								</option>
							);
						})}
					</NativeSelect>
					<section>
						{currency && <NativeSelect
							size="xs"
							onChange={(e) => { handleCurrencyChange(e.target.value) }}
							value={productsReducer.currency}
						>
							<option value={currency.name} key={currency.name}>{currency.name}</option>
							{Object.keys(currency.convertTo).map((item) => {
								return (<option value={item} key={item}>{item}</option>);
							})}
						</NativeSelect>}
						<Switch
							size="lg"
							id="filter-switch-isSupplier"
							color="var(--color-gray-blue)"
							checked={supplierCheck}
							onLabel="Proveedores"
							offLabel="Competencias"
							onChange={(event) => handleIsSupplierChange(event.currentTarget.checked)} />
						<Switch
							size="lg"
							checked={stockCheck}
							onLabel="Con stock"
							offLabel="Sin stock"
							color="var(--color-gray-blue)"
							onClick={toggleInStock}
						/>
					</section>
					<Switch
						checked={productsReducer.isUpdated}
						color="var(--color-gray-blue)"
						labelPosition="left"
						label="Solo productos actualizados"
						size="md"
						onClick={toggleIsUpdated}
					/>
					<Switch
						checked={productsReducer.hasPrice}
						color="var(--color-gray-blue)"
						labelPosition="left"
						label="Solo productos con precio"
						size="md"
						onClick={toggleHasPrice}
					/>
					{/* {(productsReducer.count > 0 || productsReducer.suggested !== null) && productsReducer.minTotalPrice !== productsReducer.maxTotalPrice && (
						<div className="filters-priceSlider">
							<div>
								<p>Precio</p>
								<p>
									{minValue !== null ? formatearNumero(minValue) : formatearNumero(productsReducer.minTotalPrice)}-{maxValue !== null ? formatearNumero(maxValue) : formatearNumero(productsReducer.maxTotalPrice)}
								</p>
							</div>
							{productsReducer.minTotalPrice !== null && <RangeSlider minRange={0.2}
								min={productsReducer.minTotalPrice}
								max={productsReducer.maxTotalPrice}
								size="xs"
								color="var(--color-gray-blue)"
								step={0.05}
								defaultValue={[minValue !== null ? minValue : productsReducer.minTotalPrice, maxValue !== null ? maxValue : productsReducer.maxTotalPrice]}
								onChange={handleSlide}
								onChangeEnd={handleLimitsChange}
							/>}
						</div>
					)} */}
					{totalFiltros.length > 0 && (
						<div>
							{totalFiltros.map((filtro, i) => {
								return <FiltroTarjeta filtro={filtro} key={i} cambiarFiltros={cambiarFiltros} />;
							})}
						</div>
					)}
				</Accordion.Panel>
			</Accordion.Item>
		</Accordion>
	);
}
