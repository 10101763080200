import React from 'react'

import SearchIcon from "@mui/icons-material/Search";

import './utilitiesComponents.css'

export const TextSearcher = ({ placeholder, setSearchFilter }) => {
    return (
        <div className="textSearcher-container" style={{ width: "300px" }}>
            <input
                placeholder={placeholder}
                autoComplete="off"
                name="email"
                onChange={(e) => {
                    setSearchFilter(e.target.value.toLowerCase());
                }}
            />
            <SearchIcon />
        </div>
    )
}
