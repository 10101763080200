import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showNavBar } from "../../redux/actions/utilities/navbarAction";

import GraficoBarra from "../charts/GraficoBarra";
import GraficoTorta from "../charts/GraficoTorta";
import GraficoLinea from "../charts/GraficoLinea";
import GraficoDona from "../charts/GraficoDona";

// import Chart from "chart.js/auto";
import ModalGrafico from "./modales/ModalGrafico";
import MenuItems from "../navbar/MenuItems";
import CardDashboard from "./CardDashboard";

// import addDashboardImage from "../../assets/svg/functionalities-icons/plus_blue.svg";

import "./home.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import ModalEditGraphic from "./modales/ModalEditGraphic";
// import { getDashboard } from "../../redux/actions/dashboard/dashboardAction";
import { QuotationRecord } from "../quotation/quotationRecord/QuotationRecord";

export default function Home() {
	const dispatch = useDispatch();
	const [mostrarModal, setMostrarModal] = useState(false);
	const [graficoEnModal, setGraficoEnModal] = useState(false);

	const [graphicModal, setGraphicModal] = useState(false);
	const [titleGraphic, setTitleGraphic] = useState("");

	const [dashboardToEdit, setDashboardToEdit] = useState(null);

	const user = useSelector((state) => state.auth.user);
	const dashboard = useSelector((dashboard) => dashboard.dashboard.dashboards);

	useEffect(() => {
		document.title = "Scope | Inicio"
		// dispatch(getDashboard());
		dispatch(showNavBar());
	}, [dispatch]);

	useEffect(() => {
		if (dashboardToEdit) {
			setTitleGraphic("Editar");
			setGraphicModal(true);
		}
	}, [dashboardToEdit]);

	const cerrarModal = () => {
		setMostrarModal(false);
		setTitleGraphic("");
		setGraphicModal(false);
		setDashboardToEdit(null);
	};

	// const handleAddDashboard = () => {
	// 	setTitleGraphic("Crear");
	// 	setGraphicModal(true);
	// };

	const handlerSelectDashboard = (dashboard) => {
		setGraficoEnModal(dashboard);
		setMostrarModal(true);
	};

	return (
		<div className="home-container">
			<MenuItems />
			{user.role &&
				<div className="home-welcome-container">
					<h1>Bienvenido</h1>
					<div>
						<h3>{user.info.lastName} {user.info.name}</h3>
						<span>{user.role.value}</span>
					</div>
				</div>}
			<div className="cuentas-contenedor home-contenedor">
				{dashboard[0] &&
					dashboard.map((dashboard) => {
						return (
							<CardDashboard
								dashboard={dashboard}
								handlerSelect={handlerSelectDashboard}
								key={dashboard._id}
								setDashboardToEdit={setDashboardToEdit}
							>
								{dashboard.data.type === "historical" ? (
									<GraficoLinea dashboard={dashboard} />
								) : dashboard.data.graphic === "barra" ? (
									<GraficoBarra dashboard={dashboard} />
								) : dashboard.data.graphic === "dona" ? (
									<GraficoDona dashboard={dashboard} />
								) : (
									<GraficoTorta dashboard={dashboard} />
								)}
							</CardDashboard>
						);
					})}
				{/* <div className="home-contenedor-card home-add_card" onClick={handleAddDashboard}>
						<img src={addDashboardImage} alt="addDashboard" />
					</div> */}
				{/* 
				<CardDashboard title="Variacion Stock Notebooks I9" handlerSelect={handlerSelectDashboard}>
					<GraficoLinea dataToShow={statictis.stadisticasstock} label="stock" />
				</CardDashboard>*/}
				{graficoEnModal && (
					<ModalGrafico open={mostrarModal} cerrarModal={cerrarModal}>
						{graficoEnModal}
					</ModalGrafico>
				)}
				{graphicModal && (
					<ModalEditGraphic open={graphicModal} title={titleGraphic} dashboard={dashboardToEdit} cerrarModal={cerrarModal} />
				)}
			</div>
			<QuotationRecord isHome={true} />
		</div>
	);
}
