import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import SelectIndex from "../../../utilities/SelectIndex"
import ConfirmationModal from '../../../utilities/Modal/ConfirmationModal';
import StatusModal from '../../../utilities/Modal/StatusModal';

import { addProductIndex, deleteProductIndex, updateProductIndex } from '../../../../redux/actions/entities/marketPlayerActions';

import iconArrow_gray from "../../../../assets/svg/functionalities-icons/arrow_gray.svg";
import infoCircle from "../../../../assets/svg/information-icons/info-circle.svg";
import iconClose from "../../../../assets/svg/functionalities-icons/cross_gray.svg";
import iconTrash from "../../../../assets/svg/functionalities-icons/trash-can.svg";

import "./indexSearch.css"
import "../../../configuration/categorization/filtros/filtros.css"

export const IndexSearchForm = ({ marketPlayerID, selectedIndex, setSelectedIndex }) => {
    const dispatch = useDispatch();

    const productIndices = useSelector((state) => state.marketPlayer.indices);
    const stateMarketPlayer = useSelector((state) => state.marketPlayer);

    const [closeSection, setCloseSection] = useState(false)

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
    const [showEditConfirmationModal, setShowEditConfirmationModal] = useState(false)
    const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState(false)

    const [showStatusModal, setShowStatusModal] = useState(false)

    const [openSelect, setOpenSelect] = useState(false);
    const [nameSelectCategory, setNameSelectCategory] = useState("Categoria");
    const [indexSelectCategory, setIndexSelectCategory] = useState(null);

    const [categoryArray, setCategoryArray] = useState([])

    useEffect(() => {
        if (selectedIndex) {
            setNameSelectCategory(selectedIndex.indexPath.split('-')[2].replace('_', ' '))
            setIndexSelectCategory(selectedIndex)
            setCategoryArray(selectedIndex.categories)

            document.getElementById("link").value = selectedIndex.link || "";
            document.getElementById("search-text").value = selectedIndex.searchText || "";
            document.getElementById("words-to-skip").value = selectedIndex.wordsToSkip || "";
            document.getElementById("words-to-search").value = selectedIndex.wordsToSearch || "";
        }
    }, [selectedIndex])

    useEffect(() => {
        const existingIndex = productIndices.filter((element) => {
            if (element.indexID === indexSelectCategory?.indexID) return element
            return null
        })

        if (existingIndex[0]) {
            setIndexSelectCategory(existingIndex[0])
            setSelectedIndex(existingIndex[0])
        }
        else {
            handleClearInputsOnly()
            setSelectedIndex(null)
        }
    }, [indexSelectCategory])

    useEffect(() => {
        handleCleanInputs()
    }, [productIndices])

    const handleAddCategory = (e) => {
        e.preventDefault()
        let formulario = document.getElementById("select-category-form")

        const newCategory = {
            category: formulario.elements["category"].value || null,
            subcategory1: formulario.elements["subcategory1"].value || null,
            subcategory2: formulario.elements["subcategory2"].value || null
        }

        let newCategoryArray = categoryArray

        newCategoryArray.push(newCategory)
        setCategoryArray(newCategoryArray)

        if (indexSelectCategory) {
            document.getElementById("category").value = "";
            document.getElementById("subcategory1").value = "";
            document.getElementById("subcategory2").value = "";
        }
    }

    const handleCategorySelect = (elementProduct) => {
        setNameSelectCategory(elementProduct.name);
        setIndexSelectCategory(elementProduct);
        setOpenSelect(false);
    };

    const handleCleanInputs = (e) => {
        if (indexSelectCategory) {
            e && e.preventDefault()
            handleClearInputsOnly();
            setCategoryArray([]);
            setNameSelectCategory("Categoria");
            setIndexSelectCategory(null);
            setShowEditConfirmationModal(false)
            setSelectedIndex(null)
        }
    }

    const handleClearInputsOnly = () => {
        if (indexSelectCategory) {
            document.getElementById("category").value = "";
            document.getElementById("subcategory1").value = "";
            document.getElementById("subcategory2").value = "";
            document.getElementById("link").value = "";
            document.getElementById("search-text").value = "";
            document.getElementById("words-to-skip").value = "";
            document.getElementById("words-to-search").value = "";
            setCategoryArray([]);
        }
    }

    const handleDeleteCategory = (indexToDelete) => {
        const newCategoryArray = categoryArray.filter((category, index) => {
            if (index !== indexToDelete) return category
            return null
        })

        setCategoryArray(newCategoryArray)
    }

    const handleDeleteIndex = () => {
        setShowStatusModal(true)
        dispatch(deleteProductIndex(marketPlayerID, selectedIndex._id))
        setSelectedIndex(null)
        setShowDeleteConfirmationModal(false)
    }

    const handleEditIndex = (e) => {
        setShowStatusModal(true)
        e.preventDefault()
        const object = objectMaker()
        dispatch(updateProductIndex(marketPlayerID, object, selectedIndex))
        setSelectedIndex(null)
        setShowEditConfirmationModal(false)
    }

    const handleSubmitClick = (e) => {
        setShowStatusModal(true)
        setShowSaveConfirmationModal(false)
        e.preventDefault()
        const object = objectMaker()
        dispatch(addProductIndex(object, marketPlayerID))
        setSelectedIndex(null)
    }

    const objectMaker = () => {
        var formulario = document.getElementById("select-category-form")

        const object = {
            categories: categoryArray,
            indexPath: indexSelectCategory.tipoProducto,
            indexID: indexSelectCategory.indexID,
            link: formulario.elements["link"].value || null,
            searchText: formulario.elements["search-text"].value.split(',') || null,
            wordsToSkip: formulario.elements["words-to-skip"].value.split(',') || null,
            wordsToSearch: formulario.elements["words-to-search"].value.split(',') || null,
        }

        return object
    }

    return (
        <>
            <div className="style-section-row" onClick={() => { setCloseSection(!closeSection) }}>
                <h2>Carga de indice de producto</h2>
                <img alt="arrow" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
            </div>
            <hr />
            <form id="select-category-form" autoComplete="off" className={closeSection ? "d-none" : undefined}>
                <section>
                    <p>Tipo de producto:</p>
                    <span
                        className="filtros-select_category"
                        onClick={() => {
                            setOpenSelect(!openSelect);
                        }}
                    >
                        {nameSelectCategory.replace(/_/g, ' ')}
                        <img src={iconArrow_gray} style={openSelect ? { rotate: "180deg" } : {}} alt="open" />
                    </span>
                    <div>
                        <SelectIndex openSelectIndex={openSelect} handleCategorySelect={handleCategorySelect} />
                    </div>
                </section>

                {(indexSelectCategory || selectedIndex) &&
                    <>
                        <section>
                            <label htmlFor="link">Link:</label>
                            <input id="link" />
                        </section>
                        <section>
                            <label htmlFor="search-text">Texto de busqueda:</label>
                            <input id="search-text" />
                            <section className="select-category-anotation-section">
                                <img src={infoCircle} alt="info-icon" />
                                <h5>Separar las busquedas por comas</h5>
                            </section>
                        </section>
                        <div>
                            <section>
                                <label htmlFor="category">Categoria:</label>
                                <input id="category" />
                            </section>
                            <section>
                                <label htmlFor="subcategory1">Subcategoria 1:</label>
                                <input id="subcategory1" />
                            </section>
                            <section>
                                <label htmlFor="subcategory2">Subcategoria 2:</label>
                                <input id="subcategory2" />
                            </section>
                            <button onClick={(e) => handleAddCategory(e)} className="indexSearch-form-confirm-button">Agregar +</button>
                        </div>
                        {categoryArray.map((category, index) => {
                            return <article key={index}>
                                <p><strong>Categoria:</strong> {category.category}</p>
                                <hr />
                                <p><strong>Subcategoria 1:</strong> {category.subcategory1}</p>
                                <hr />
                                <p><strong>Subcategoria 2:</strong> {category.subcategory2}</p>
                                <img alt="close" src={iconClose} onClick={() => handleDeleteCategory(index)} />
                            </article>
                        })}
                        <section className="indexSearch-specialWords">
                            <section>
                                <label htmlFor="words-to-skip">Palabras a omitir:</label>
                                <input id="words-to-skip" />
                            </section>
                            <section>
                                <label htmlFor="words-to-search">Palabras clave:</label>
                                <input id="words-to-search" />
                            </section>
                        </section>
                        <div style={{ margin: "auto" }}>
                            <section>
                                <img src={infoCircle} alt="info-icon" />
                                <h5>Separar las palabras por comas</h5>
                            </section>
                        </div>
                        <div className="indexSearch-form-buttons">
                            {selectedIndex && <img src={iconTrash} type="button" alt="delete" onClick={() => setShowDeleteConfirmationModal(true)} />}
                            <button onClick={(e) => handleCleanInputs(e)}>Cancelar</button>
                            {selectedIndex ? <button onClick={() => setShowEditConfirmationModal(true)} disabled={!indexSelectCategory}>Editar</button>
                                : <button onClick={() => setShowSaveConfirmationModal(true)} disabled={!indexSelectCategory}>Guardar</button>}
                        </div>
                    </>
                }

                {showDeleteConfirmationModal && <ConfirmationModal
                    setShowConfirmationModal={setShowDeleteConfirmationModal}
                    handlerShowConfirmationModal={handleDeleteIndex}
                    titleText="Eliminar indice"
                    descriptionText={`¿Está seguro que desea eliminar el indice "${nameSelectCategory.charAt(0).toUpperCase() + nameSelectCategory.slice(1)}"?`}
                />}

                {showEditConfirmationModal && <ConfirmationModal
                    setShowConfirmationModal={setShowEditConfirmationModal}
                    handlerShowConfirmationModal={(e) => handleEditIndex(e)}
                    titleText="Editar indice"
                    descriptionText={`¿Está seguro que desea editar el indice "${nameSelectCategory.charAt(0).toUpperCase() + nameSelectCategory.slice(1)}"?`}
                />}

                {showSaveConfirmationModal && <ConfirmationModal
                    setShowConfirmationModal={setShowSaveConfirmationModal}
                    handlerShowConfirmationModal={(e) => handleSubmitClick(e)}
                    titleText="Guardar indice"
                    descriptionText={`¿Está seguro que desea guardar el indice "${nameSelectCategory}"?`}
                />}

                {showStatusModal && (
                    <StatusModal loading={stateMarketPlayer.loading} error={stateMarketPlayer.error} setModalStatusCloseAction={setShowStatusModal}>
                        {stateMarketPlayer.loading
                            ? "Procesando..."
                            : stateMarketPlayer.message}
                    </StatusModal>
                )}

            </form>
        </>
    )
}
