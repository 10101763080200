import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import ArrowOpenCategory from "../../assets/svg/functionalities-icons/arrow-close_blue.svg";
import ArrowClose from "../../assets/svg/functionalities-icons/arrow-open_white.svg";

import "./utilities.css";

export const SelectSubcategory = ({ openSelectSubcategory, handleSubcategorySelect, subcategoryExcept }) => {
    const navbarCategories = useSelector((state) => state.navbar.categories);

    const [categories, setCategories] = useState([""]);
    const [openCategories, setOpenCategories] = useState([]);

    const [subCategories, setOpenSubcategory] = useState([""]);

    useEffect(() => {
        setCategories(navbarCategories.map((category) => { return category.name }));
        setOpenCategories(navbarCategories.map(() => { return false }))
    }, [navbarCategories]);

    const handleClickCategory = (position) => {
        setOpenCategories(
            openCategories.map((data, i) => {
                if (i === position) return !data;
                return false;
            })
        );
        setOpenSubcategory(navbarCategories[position].subcategories.filter((subcategory) => {
            const subcatsIDs = subcategory.indices.map(index => index._id)
            if (!subcatsIDs.includes(subcategoryExcept)) {
                return subcategory;
            };
            return;
        }))
    };

    return (
        <div className={categories.length !== 0 && openSelectSubcategory ? "selectCategory-container" : "d-none"}>
            {categories.map((element, index) => (
                <div className="borderBottom" key={uuidv4()}>
                    <div
                        className={openCategories[index] ? "selectCategory-container-row dropdowbCategoryRowOpen" : "selectCategory-container-row"}
                        onClick={() => handleClickCategory(index)}
                    >
                        <h6>{element.toUpperCase()}</h6>
                        <img src={openCategories[index] ? ArrowClose : ArrowOpenCategory} alt="flecha" />
                    </div>
                    <div className="selectCategory-dropdown-category">
                        {openCategories[index] &&
                            subCategories.map((elementProduct) => (
                                <div key={uuidv4()}>
                                    <div
                                        className="selectCategory-dropdown-subcategory"
                                        style={{ display: "flex", padding: "15px 20px 15px 40px", justifyContent: "space-between" }}
                                        onClick={() => {
                                            handleSubcategorySelect(elementProduct);
                                        }}
                                    >
                                        <h3>{elementProduct.name}</h3>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

