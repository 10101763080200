import { Navigate, Outlet } from "react-router";
import { useSelector } from "react-redux";

const PrivateSuperUserRoutes = () => {
	const userAccess = useSelector((state) => state.auth.user.role?.access);

	return userAccess && userAccess === "superUser" ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateSuperUserRoutes;
