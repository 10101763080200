import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { deletePriceList, getPriceLists, postPriceList, updatePriceList } from '../../../redux/actions/utilities/priceListAction';
import { deleteCommercialCondition, getCommercialConditions, postCommercialCondition, updateCommercialCondition } from '../../../redux/actions/utilities/commecialConditionAction';

import PriceList from './priceList/PriceList';
import ConfirmationModal from '../../utilities/Modal/ConfirmationModal';
import StatusModal from '../../utilities/Modal/StatusModal';
import CommercializationCreationModal from './modals/CommercializationCreationModal';
import { CommercialCondition } from './commercialCondition/CommercialCondition';

import './commercialization.css'

export const Commercialization = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth);
    const priceList = useSelector((state) => state.priceList);
    const commercialCondition = useSelector((state) => state.commercialCondition);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showCreationModal, setShowCreationModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)

    document.title = "Scope | Comercialización"

    useEffect(() => {
        if (!showStatusModal) {
            dispatch(getPriceLists());
            dispatch(getCommercialConditions())
        }
    }, [showStatusModal, dispatch])

    const handlerShowConfirmationModal = () => {
        if (showConfirmationModal.type === 'lista de precio') {
            dispatch(deletePriceList(showConfirmationModal.id))
        } else {
            dispatch(deleteCommercialCondition(showConfirmationModal.id))
        }

        setShowConfirmationModal(false);
        setShowStatusModal(true)
    }

    const handlerCreationModalAction = (name, percentage) => {
        if (showCreationModal.action === 'Crear') {
            if (showCreationModal.type === 'Lista de precio') dispatch(postPriceList(name, percentage))
            else dispatch(postCommercialCondition(name, percentage))
        } else {
            if (showCreationModal.type === 'Lista de precio') dispatch(updatePriceList(name, percentage, showCreationModal.values._id));
            else dispatch(updateCommercialCondition(name, percentage, showCreationModal.values._id))
        }

        setShowCreationModal(false)
        setShowStatusModal(true)

    }

    return (
        <div className="commercialization-container">

            {user.user.role && user.user.role.access !== "user" && (
                <PriceList setShowConfirmationModal={setShowConfirmationModal} setShowCreationModal={setShowCreationModal} />
            )}
            {user.user.role && user.user.role.access !== "user" && (
                <CommercialCondition setShowConfirmationModal={setShowConfirmationModal} setShowCreationModal={setShowCreationModal} />
            )}

            {showConfirmationModal &&
                <ConfirmationModal
                    descriptionText={`¿Está seguro que quiere eliminar la ${showConfirmationModal.type} "${showConfirmationModal.name}"?`}
                    handlerShowConfirmationModal={handlerShowConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    titleText={`Eliminar ${showConfirmationModal.type}`}
                />}
            {showCreationModal &&
                <CommercializationCreationModal
                    handlerCreationModalAction={handlerCreationModalAction}
                    showCreationModal={showCreationModal}
                    setShowCreationModal={setShowCreationModal}
                />}
            {showStatusModal && (
                <StatusModal loading={priceList.loading || commercialCondition.loading} error={priceList.error || commercialCondition.error} setModalStatusCloseAction={setShowStatusModal}>
                    {priceList.message || commercialCondition.message}
                </StatusModal>
            )}
        </div>
    )
}
